import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { env } from "../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
import LoadingToastEnable from "../../../components/Totp/LoadingToastEnable";
const baseUrl = env.REACT_APP_BASE_URL;

export const initializeTotp = createAsyncThunk(
  "initializeTotp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/users/init_two_fa`,
        {
          id: values?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors ||
        error.response?.data?.errors?.base[0] ||
        error.response?.data?.message ||
        error.message;
      toast.error(errorMessage, toast_position);
      return rejectWithValue(errorMessage);
    }
  }
);

export const disableTotp = createAsyncThunk(
  "disableTotp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/users/disable_two_fa`,
        {
          id: values?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      values?.setCurrentDisplay("enabled");
      values?.setOpenDisabledPop(false);
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors ||
        error.response?.data?.errors?.base[0] ||
        error.response?.data?.message ||
        error.message;
      values?.setOpenDisabledPop(false);
      return rejectWithValue(errorMessage);
    }
  }
);

export const enableTotp = createAsyncThunk(
  "enableTotp",
  async (values, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${baseUrl}/users/enable_two_fa`,
        {
          id: values?.id,
          totp: values?.totp,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": sessionStorage.getItem("q2p_token"),
          },
        }
      );
      return response.data;
    } catch (error) {
      const errorMessage =
        error.response?.data?.errors?.base[0] ||
        error.response?.data?.message ||
        error.message;
      return rejectWithValue(errorMessage);
    }
  }
);

const totp = createSlice({
  name: "totp",
  initialState: {
    initializeTotpLoading: null,
    initializeTotpData: null,
    initializeTotpError: null,
    initializeTotpErrorMsg: null,
    enableTotpLoading: null,
    enableTotpData: null,
    enableTotpError: null,
    disableTotpLoading: null,
    disableTotpData: null,
    disableTotpError: null,
  },
  reducers: {
    disabledEnabledError(state) {
      state.enableTotpError = null;
      state.enableTotpData = null;
    },
    removeInitialError(state) {
      state.initializeTotpData = null;
      state.initializeTotpError = null;
      state.initializeTotpErrorMsg = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(initializeTotp.pending, (state) => {
      state.initializeTotpLoading = true;
    });
    builder.addCase(initializeTotp.fulfilled, (state, action) => {
      state.initializeTotpLoading = false;
      state.initializeTotpData = action.payload;
    });
    builder.addCase(initializeTotp.rejected, (state, action) => {
      state.initializeTotpError = true;
      state.initializeTotpLoading = false;
      state.initializeTotpErrorMsg = action.payload;
    });

    builder.addCase(enableTotp.pending, (state) => {
      state.enableTotpLoading = true;
      toast.dismiss();
      LoadingToastEnable("enable");
    });
    builder.addCase(enableTotp.fulfilled, (state, action) => {
      state.enableTotpLoading = false;
      state.enableTotpData = action.payload;
      toast.dismiss();
      toast.success("TOTP has been enabled successfully", toast_position);
    });
    builder.addCase(enableTotp.rejected, (state, action) => {
      state.enableTotpError = true;
      state.enableTotpLoading = false;
      toast.dismiss();
      toast.error(action?.payload, toast_position);
    });

    builder.addCase(disableTotp.pending, (state) => {
      state.disableTotpLoading = true;
      toast.dismiss();
      LoadingToastEnable("disable");
    });
    builder.addCase(disableTotp.fulfilled, (state, action) => {
      state.disableTotpLoading = false;
      state.disableTotpData = action.payload;
      toast.dismiss();
      toast.success("TOTP has been disabled successfully", toast_position);
    });
    builder.addCase(disableTotp.rejected, (state, action) => {
      state.disableTotpError = true;
      state.disableTotpLoading = false;
      toast.dismiss();
      toast.error(action.payload, toast_position);
    });
  },
});

export const { disabledEnabledError, removeInitialError } = totp.actions;

export default totp.reducer;
