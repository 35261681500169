import React, { useState } from "react";
import "./index.css";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const TotpStepper = ({
  activeStep,
  handleBack,
  steps,
  handleNext,
  nextButtonEnabled,
  direction,
  setCurrentDisplay,
  backupCodes,
}) => {
  const [disabled, setDisabled] = useState(false);
  const downloadTxtFile = () => {
    const formattedText = backupCodes.join("\n");
    const blob = new Blob([formattedText], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "backupCodes.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const handleTotp = async () => {
    setDisabled(true);
    downloadTxtFile();
    setCurrentDisplay("disabled");
    toast.success("TOTP setup completed successfully", toast_position);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
  };

  return (
    <div className="internal-stepper-main">
      <Button
        disabled={activeStep === 0}
        className={`custom_create ${activeStep === 0 ? "inactive" : "active"}`}
        style={{
          backgroundColor: "#506994",
          color: "#FFFFFF",
          opacity: activeStep === 0 ? "0.4" : "1",
        }}
        sx={{ borderRadius: 0 }}
        onClick={handleBack}
      >
        Previous
      </Button>
      <div className="internal-stepperline_Div_biller">
        {steps?.map((val, index) => {
          return (
            <div className="stepinnerline" key={index}>
              <div
                className={`stepDetails ${
                  activeStep !== index ||
                  (activeStep === 0 && direction === "next")
                    ? ""
                    : "growing-element"
                }`}
                style={{
                  opacity: activeStep === index ? "1" : "0",
                }}
              >
                {activeStep === index ? val : "Hello"}
              </div>
              <div
                className={`internal-main_Line ${
                  activeStep !== index ||
                  (activeStep === 0 && direction === "next")
                    ? ""
                    : "growing-element"
                }`}
                style={{
                  width: activeStep === index ? "220px" : "100px",
                  opacity: activeStep === index ? "1" : "0.5",
                }}
              ></div>
            </div>
          );
        })}
      </div>
      {activeStep === 3 ? (
        <Button
          className={`create_Button ${
            activeStep === 3 ? "active" : "inactive"
          }`}
          onClick={handleTotp}
          sx={{ borderRadius: 0 }}
          disabled={disabled}
        >
          Download
        </Button>
      ) : (
        <Button
          disabled={!nextButtonEnabled}
          className={`custom_create_next ${
            !nextButtonEnabled ? "inactive" : "active"
          }`}
          style={{
            backgroundColor: !nextButtonEnabled ? "#656565" : "#F9F9F9",
            color: !nextButtonEnabled ? "#FFFFFF" : "#1D3A6D",
          }}
          sx={{ borderRadius: 0 }}
          onClick={handleNext}
        >
          Next
        </Button>
      )}
    </div>
  );
};

export default TotpStepper;
