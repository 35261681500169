import React, { useState, useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GeoMapLeaflet from "../../../../components/_utils/GeoMapLeaflet";
import { checkUsertoken } from "../../../../redux/features/login/login";
import formatdate from "../../../../utils/formatdate";
import { getOwnerAccountIFSCDetails } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import ArrowLeft from "../../../../assets/ArrowsIcons/ChevronLeftWhite.svg";

const OwnerAccountIFSC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [ownerDetailIfscData, setOwnerdetailIfscdata] = useState([]);

  const { ownerAccIfscData, ownerAccIfscLoading } = useSelector(
    (state) => state.ownerAllVerification
  );

  useEffect(() => {
    async function fetchIFSCData() {
      setLoading(true);
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(
          getOwnerAccountIFSCDetails({ search_string: id })
        );
        if (response?.error?.message == "Request failed with status code 404") {
          navigate("*");
        }
        setOwnerdetailIfscdata(response.payload.ifsc_details[0]);
      } catch (error) {
        console.error("Error", error);
      } finally {
        setLoading(false);
      }
    }

    fetchIFSCData();
  }, [id]);

  const {
    bank,
    ifsc,
    contact,
    micr,
    state,
    last_updated,
    address,
    branch,
    city,
  } = ownerDetailIfscData;

  return (
    <>
      <div className="flex flex-col h-screen overflow-auto" id="container">
        <div
          className="lg:hidden fixed w-full top-0 bg-[#3A5686] sm:h-14 flex items-center cursor-pointer"
          style={{ zIndex: 999 }}
          onClick={() => navigate(-1)}
        >
          <img src={ArrowLeft} alt="Close" className="w-4 h-4 m-5" />
          <h1 className="text-base flex-1 text-[#F9F9F9]">IFSC details</h1>
        </div>
        <div className="w-auto bg-[#314D7E] ContainerIfscDetails">
          <div className="max-w-[1024px] mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 items-center border-b border-[#1D3A6D] py-4 lg:py-[25px] md:py-[25px]">
              <div className="border-b md:border-b-0 md:border-r border-[#1D3A6D] flex justify-between items-center pb-4 md:pb-0">
                <div className="flex flex-col gap-1">
                  <p className="text-sm text-Primary_white">{ifsc}</p>
                  <p className="text-xs text-Primary_grey">IFSC code</p>
                </div>
                <div className="bg-Primary_white p-2 rounded-[20px] w-20 h-8 flex justify-center items-center mr-2.5">
                  <img
                    src={require("../../../../assets/IfscLogo/" +
                      IfscCodeMapper(ifsc))}
                    alt="bank"
                    className="w-14"
                  />
                </div>
              </div>

              <div className="md:col-span-2 flex flex-col gap-1 border-b md:border-b-0 md:border-r border-transparent lg:px-4 md:px-2 sm:px-0 sm:pb-0 md:pb-0 lg:pb-0 pt-4 lg:pt-0 md:pt-2 sm:pt-4">
                <p className="text-sm text-Primary_white">{bank}</p>
                <p className="text-xs text-Primary_grey">Bank name</p>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 items-center py-4 lg:py-[25px] md:py-8">
              <div className="flex flex-col gap-1 border-b md:border-b-0 md:border-r border-[#1D3A6D] pr-4 pb-4 md:pb-0">
                <p className="text-sm text-Primary_white">
                  {contact
                    ? `+${contact.substring(0, 2)} - ${contact.substring(2)}`
                    : `Not Available`}
                </p>
                <p className="text-xs text-Primary_grey">Contact number</p>
              </div>

              <div className="flex flex-col gap-1 border-b md:border-b-0 md:border-r border-[#1D3A6D] lg:px-4 md:px-2 sm:px-0 pb-4 sm:pb-4 md:pb-0 lg:pb-0 pt-4 lg:pt-0 md:pt-2 sm:pt-4">
                <p className="text-sm text-Primary_white">
                  {micr ? micr : "Not Available"}
                </p>
                <p className="text-xs text-Primary_grey">MICR</p>
              </div>

              <div className="flex flex-col gap-1 border-b md:border-b-0 md:border-r border-transparent lg:px-4 md:px-2 sm:px-0 sm:pb-4 md:pb-0 lg:pb-0 pt-4 lg:pt-0 md:pt-2 sm:pt-4">
                <p className="text-sm text-Primary_white">
                  {formatdate(last_updated)}
                </p>
                <p className="text-xs text-Primary_grey">Last updated</p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-[1024px] w-full mx-auto px-4 pb-5 lg:pb-0 md:pb-0 pt-5 lg:pt-0 md:pt-0">
          <button className="bg-Primary_white text-[#373F4B] rounded-4xl px-4 py-1 text-xs font-semibold my-5 hidden md:block">
            Address
          </button>

          <div className="bg-[#314D7E] w-full flex flex-col md:flex-row items-start justify-between rounded-[20px] p-5 gap-4">
            <div className="w-full md:w-[60%] flex flex-col gap-1">
              <p className="text-Primary_grey text-sm">Corporate Address</p>
              <p className="text-Primary_white text-sm">
                {address?.toLowerCase()}
              </p>
            </div>
            <div className="w-full md:w-[40%] flex justify-center md:justify-end">
              <GeoMapLeaflet owner={"ownerDetails"} address={address} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerAccountIFSC;
