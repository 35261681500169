import React, { useEffect, useRef } from "react";
import { debounce } from "@mui/material";
import "./index.css";
import CircleCancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import Search from "../../../assets/FilterSearchIcons/search_white.svg";

const RectangleSearchBar = (props) => {
  const {
    showSearchBar,
    handleSearch,
    setSearchUserText,
    setCurrentPage,
    searchUserText,
    searchRef,
    placeholder,
    setIsAssignTagsClicked,
    setAssignTagsAccounts,
  } = props;

  const inputref = useRef(null);

  useEffect(() => {
    if (searchUserText.length > 0) {
      inputref.current.value = searchUserText;
    }
  }, []);

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text");
    setCurrentPage(1);
    handleSearch(pastedText);
  };

  const handlechange = debounce((e) => {
    setCurrentPage(1);
    handleSearch(e.target.value);
    // setIsAssignTagsClicked(false);
    // if (setAssignTagsAccounts) {
    //   setAssignTagsAccounts([]);
    // }
  }, 500);

  const handleClear = () => {
    setCurrentPage(1);
    setSearchUserText("");
    inputref.current.value = "";
  };

  return (
    <div
      ref={searchRef}
      style={{ border: props?.multiSelect && "none" }}
      className={`searchComponent-bar ${
        showSearchBar ? "searchComponent_expand" : "searchComponent_collapse"
      }`}
    >
      <label htmlFor="search" className="sr-only">
        Search
      </label>

      <div className="searchComponent_bar_acc">
        <img
          className="searchComponent_inner_image_acc"
          src={Search}
          alt="search-inner-image"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
        <input
          ref={inputref}
          autoFocus
          onKeyUp={handlechange}
          onPaste={handlePaste}
          autoComplete="off"
          id="searchComponent"
          name="search"
          className={`block h-8 rounded-[10px] bg-[#244277] text-[#F9F9F9] placeholder:text-[#DADADA] placeholder:text-[12px] placeholder:ml-[30px] outline-none`}
          placeholder={placeholder || ""}
          type="text"
        />
        {searchUserText && (
          <img
            onClick={handleClear}
            className="searchComponent_close_image_acc cursor-pointer"
            src={CircleCancel}
            alt="search-close"
          />
        )}
      </div>
    </div>
  );
};

export default RectangleSearchBar;
