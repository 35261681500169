import React, { useState, useEffect } from "react";
import "./index.css";
import Decline from "../../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import Approved from "../../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import Close from "../../../../assets/CloseIcons/close_with_white_circle.svg";
import RightArrow from "../../../../assets/ArrowsIcons/arrow_right_white_filled.svg";
import WhiteReload from "../../../../assets/RefreshIcons/reload-refresh_white.svg";
import { viewAccountDetail } from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneTxnAccountDetail } from "../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import addStatus from "../../../../assets/InfoIcons/add_status.svg";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Filter from "../../../../assets/Approve transactions/filter.png";
import ApproveWhite from "../../../../assets/CheckmarksIcons/approve_white.svg";
import RejectedWhite from "../../../../assets/CloseIcons/rejected_white.svg";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import {
  getCreateTransactionBalance,
  resetOwnerBalance,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { Tooltip, tooltipClasses } from "@mui/material";
import { beneTransactionMultiApproval } from "../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { Bounce, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import whiteLoader from "../../../../assets/LoaderIcons/xpentra lodemore 1.svg";
import ListWhite from "../../../../assets/OwnerAccount/listview-white.png";
import ArrowLeftWhite from "../../../../assets/ArrowsIcons/arrow_left_white.svg";
import BalanceLoader from "../../../../components/_utils/BalanceLoader";
import RefreshBalanceToast from "../../../../components/_utils/RefreshBalanceToast";
import { checkUsertoken } from "../../../../redux/features/login/login";
import AnimationUpAndDown from "../../../../components/BeneTransaction/OwnerPopup/AnimationUpAndDown";
import InternalDeclinePopup from "../../InternalTransactionSingleApproval/InternalDeclinePopup";
import FormatAmount from "../../../../components/_utils/FormatAmount/FormatAmount";
import {
  internalTxnMultiApproval,
  resetITMultiRejectApprovalData,
} from "../../../../redux/features/internalTransaction/internalTransactionSingleApproval";
import ArrowRightBlue from "../../../../assets/ArrowsIcons/arrow_right_blue.svg";
import LoaderIcon from "../../../../assets/LoaderIcons/Loader.gif";
import InternalTxnDetailPagePopup from "../../../../components/InternalTransactionMain/InternalTxnDetailPopup";
import confidential_transactionImg from "../../../../assets/GeneralIcons/confidential_transaction.svg";

const InternalMultiApprovalTxnDetails = ({ state }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showTxnDetailPop, setShowTxnDetailPop] = useState(false);
  const [txnId, setTxnId] = useState(null);
  const [DrawerStatus, SetDrawerStatus] = useState("");
  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const [BeneTxnDetailsId, SetBeneTxnDetailsId] = useState([]);
  const [isMobileResponsive, setIsMobileResponsive] = useState(
    window.innerWidth <= 800
  );
  const [widthCheck, setIswidthCheck] = useState(window.innerWidth <= 950);
  const [datas, setDatas] = useState([]);
  const [AllBeneTxnAccounts, setAllBeneTxnAccounts] = useState([]);
  const [refreshId, setRefreshId] = useState("");
  const [refreshAnimation, setrefreshAnimation] = useState("");
  const [PrevBalance, setPrevBalance] = useState(null);
  const [transactionFilterData, setTransactionFilterData] = useState([]);
  const [transactionFilterID, setTransactionFilterID] = useState([]);
  const [isInsufficientFunds, setIsInsufficientFunds] = useState(false);
  const [sideDrawerDisabled, setSideDrawerDisabled] = useState(false);
  const [isApprovalError, setIsApprovalError] = useState(false);
  const [isApprovingTxn, setApprovingTxn] = useState(false);
  const { showReloginModal } = useSelector((state) => state.login);
  const [showMultiTxnDetailPop, setShowMultiTxnDetailPop] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const TimestampConverter = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const formattedDate = date.toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    return formattedDate;
  };
  useEffect(() => {
    const storedSelectedApproval = JSON.parse(
      sessionStorage.getItem("selectedData")
    );

    if (storedSelectedApproval) {
      setTransactionFilterData(
        storedSelectedApproval?.selectedSendTransactions
      );
      setTransactionFilterID(storedSelectedApproval.selectedTransactions);
    } else {
      setTransactionFilterData([]);
      setTransactionFilterID([]);
    }
  }, []);
  const openBeneTxnDetailModal = () => {
    setShowTxnDetailPop(true);
  };
  const closeBeneTxnDetailModal = () => {
    setShowTxnDetailPop(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobileResponsive(window.innerWidth <= 800);
      setIswidthCheck(window.innerWidth <= 950);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const {
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
    createTransactionBankRefreshData,
  } = useSelector((state) => state.ownerAllVerification);

  const handleRefresh = (e, id, txnId, prevBalance) => {
    e.stopPropagation();
    setPrevBalance(prevBalance);
    setrefreshAnimation(id);
    setRefreshId(txnId);
    dispatch(checkUsertoken());
    dispatch(getCreateTransactionBalance(id));
  };

  useEffect(() => {
    if (createTransactionBankRefreshLoading) {
      toast.dismiss();
      setTimeout(() => {
        RefreshBalanceToast();
      }, 200);
    } else if (createTransactionBankRefreshData?.status) {
      toast.dismiss();
      setTimeout(() => {
        toast.success("Balance refreshed successfully", toast_position);
      }, 500);
      // setTimeout(() => {
      //   setRefreshId(null);
      // }, 5000);
    } else if (
      createTransactionBankRefreshError &&
      !createTransactionBankRefreshLoading
    ) {
      toast.dismiss();
      setTimeout(() => {
        toast.error(createTransactionBankRefreshError, toast_position);
      }, 100);
    }
    if (createTransactionBankRefreshData) {
      let data = transactionFilterData.map((txn) => {
        if (
          txn?.owner_account_id ===
          createTransactionBankRefreshData?.owner_accounts[0]?.owner_account_id
          // &&
          // txn?.txn_id === refreshId
        ) {
          return {
            ...txn,
            owner_account_balance:
              createTransactionBankRefreshData?.owner_accounts[0]
                ?.owner_account_balance,
            owner_account_balance_last_fetched_at_in_words:
              createTransactionBankRefreshData?.owner_accounts[0]
                ?.owner_account_balance_last_fetched_at_in_words,
          };
        }
        return txn;
      });

      setTransactionFilterData(data);
      const selectedData = {
        selectedSendTransactions: data,
        selectedTransactions: transactionFilterID,
      };

      sessionStorage.setItem("selectedData", JSON.stringify(selectedData));
    }
    return () => {
      dispatch(checkUsertoken());
      dispatch(resetOwnerBalance());
    };
  }, [
    createTransactionBankRefreshData,
    createTransactionBankRefreshLoading,
    createTransactionBankRefreshError,
  ]);
  let total = transactionFilterData?.reduce((acc, obj) => {
    return acc + parseInt(obj?.amount);
  }, 0);

  const handleOpenPopup = (id) => {
    setShowPopup(true);
    // SetdeniedId(id);
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const updateAllAccounts = (updatedAccounts) => {
    setAllBeneTxnAccounts(updatedAccounts);
    // SetstatustxnApproval("TxnReQuiredApproval");
  };
  const SideDrawerOpen = async (status, id, txnId) => {
    SetDrawerStatus(status);

    setisDrawerOpen(!isDrawerOpen);
    dispatch(checkUsertoken());
    await dispatch(viewAccountDetail({ id }));
    await dispatch(beneTxnAccountDetail(txnId));
  };

  const handleTxnApproval = async (id) => {
    setLoading(true);

    const imageStyle = {
      width: "40px",
      height: "40px",
      opacity: 1,
    };

    const paragraphStyle = {
      width: "160px",
      height: "19px",
      textAlign: "left",
      font: "normal normal normal 14px/19px Open Sans",
      letterSpacing: "0px",
      color: "#F9F9F9",
      opacity: 1,
    };
    const divStyle = {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
      alignItems: "center",
    };

    {
      isMobileResponsive
        ? //  <>
          setApprovingTxn(true)
        : //  </>
          toast(
            <div style={divStyle}>
              <img src={whiteLoader} alt="" style={imageStyle} />
              <p style={paragraphStyle}>Approving transaction</p>
            </div>,
            {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              fontSize: "20px",
              transition: Bounce,
              style: {
                width: "252px",
                height: "84px",
                borderRadius: "10px",
                backgroundColor: "#AE9041",
                color: "#fff",
              },
            }
          );
    }

    if (transactionFilterID.length > 0) {
      dispatch(checkUsertoken());
      dispatch(resetITMultiRejectApprovalData());
      const data = await dispatch(internalTxnMultiApproval(id));

      setTimeout(() => {
        setLoading(false);
        setApprovingTxn(false);
        toast.dismiss();
        if (data?.payload?.status === 500) {
          setTimeout(() => {
            toast.error(data?.payload?.error, toast_position);
          }, 1000);
        }
        if (data?.payload?.errors?.user_unauthorized?.[0]) {
          setTimeout(() => {
            // toast.error(
            //   data?.payload?.errors?.user_unauthorized?.[0],
            //   toast_position
            // );
            navigate("/feature_not_assigned", {
              state: {
                unavailableService: "VBeneficiaryTxn",
              },
            });
          }, 1000);
        } else if (data.payload.status === true) {
          const selectedData = {
            selectedSendTransactions: data?.payload?.transactions,
            selectedTransactions: transactionFilterID,
          };

          sessionStorage.setItem("selectedData", JSON.stringify(selectedData));
          navigate("/internal_transaction/multi_approval_transaction/success");
        } else if (data.payload.status === false) {
          navigate("/internal_transaction/multi_approval_transaction/error");
          setIsApprovalError(true);
        }
      }, 2000);
    }
  };

  const openMultiTxnDetailModal = () => {
    setShowMultiTxnDetailPop(true);
  };

  const closeInternalTxnDetailModal = () => {
    setShowMultiTxnDetailPop(false);
  };

  useEffect(() => {
    if (isApprovalError && showReloginModal === false) {
      navigate("/internal_transaction/multi_approval_transaction/error");
    }
  }, [showReloginModal, isApprovalError]);

  useEffect(() => {
    const hasInsufficientFunds = transactionFilterData.some(
      (txn) => parseInt(txn?.amount) > parseFloat(txn?.owner_account_balance)
    );

    setIsInsufficientFunds(hasInsufficientFunds);
  }, [transactionFilterData]);

  return (
    <>
      {showPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom-backdrop bg-opacity-80">
          <InternalDeclinePopup
            onClose={handleClosePopup}
            accountId={transactionFilterID}
            allAccounts={transactionFilterData}
            updateAllAccounts={updateAllAccounts}
            status={"multiTransaction"}
            sideDrawer={"multiApproval"}
            typeMulti={"internalMultiApproval"}
          />
        </div>
      )}
      {isMobileResponsive ? (
        <div
          className=""
          style={{
            height: "100vh",
            overflow: "hidden",
            position: "relative",
          }}
        >
          <div className="flex px-[15px] items-center h-[60px] bg-[#3A5686] bg-[0%_0%] bg-no-repeat opacity-100">
            <div
              className="txnBeneapprovalheaderarrowvcv-mob"
              onClick={() => navigate(-1)}
            >
              <img
                src={ArrowLeftWhite}
                style={{
                  width: "16px",
                  height: "16px",
                  marginLeft: "10px",
                }}
                alt=""
              />
            </div>
            <div className="txnBeneapprovalheadertextmv-mob">
              <div className="headingAccountDetail">Internal</div>
              <div className="headertextbottomv_mob">Transaction approval</div>
            </div>
          </div>
          <div className="h-[76px] bg-[#3A5686] bg-[0%_0%] bg-no-repeat opacity-100 mt-[1px] pl-[30px]">
            <div className="flex h-full justify-between items-center">
              <div className="flex flex-col">
                <p className="h-[22px] text-left text-[16px] font-semibold leading-[22px] text-[#4DDD37] whitespace-nowrap">
                  ₹ {<FormatAmount price={total} />}
                </p>
                <span className=" text-left text-[10px] font-normal  text-[#DADADA]">
                  Transaction Amount
                </span>
              </div>
              <div className="flex flex-col justify-center items-center w-[80px] h-[76px] bg-[#506994] bg-[0%_0%] bg-no-repeat opacity-100">
                <div className="flex items-center justify-center w-[40px] h-[40px] bg-[#667CA3] bg-[0%_0%] bg-no-repeat opacity-100 rounded-4xl">
                  <p className="w-[10px] flex justify-center items-center font-semibold text-[16px]  font-sans tracking-normal text-white opacity-100">
                    {transactionFilterData?.length}
                  </p>
                </div>
                <span className="flex items-center mt-[7px] w-[40px] text-[10px] font-normal leading-[14px] text-[#F9F9F9]">
                  Selected
                </span>
              </div>
            </div>
          </div>
          <div
            className="w-full h-[130px] bg-[#3A5686] flex flex-col gap-2 justify-center px-[15px] py-[10px]"
            style={{ borderTop: "1px solid #1D3A6D" }}
          >
            <p className="font-semibold text-sm text-[#F9F9F9]">
              Post Approval Process
            </p>
            <span className="w-full font-[400] text-xs text-left text-[#F9F9F9]">
              Once approved, distribution of the transaction will get created
              and auto settled using selected source account. If there are
              insufficient funds, the transaction will settle once the source
              account is refilled, and distribution will be initiated
              thereafter.
            </span>
          </div>

          <div
            className=" w-full mx-auto"
            style={{
              height: "calc(100vh - 336px)",
              overflow: "hidden",
              overflowY: "scroll",
              paddingBottom: "26px",
            }}
          >
            {transactionFilterData?.map((txn, i) => {
              return (
                <div
                  key={i}
                  className="h-[169px] bg-[#506994] rounded-[10px] opacity-100 mx-[15px] mt-[15px] mb-[8px] relative"
                >
                  <div className="h-[105px] bg-[#3a5686] rounded-tl-[10px] rounded-tr-[10px] opacity-100 pl-[15px] flex flex-col justify-between pt-[15px]">
                    <h4 className="w-[186px] h-[22px] font-semibold text-[16px] text-[#4DDD37] whitespace-nowrap">
                      ₹ {<FormatAmount price={txn?.amount} />}
                    </h4>
                    {txn?.confidential_transaction === "Y" ? (
                      <span>
                        <img
                          className="w-[16px] h-[16px]"
                          src={confidential_transactionImg}
                          alt="confidential"
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                    <Tooltip
                      componentsProps={{
                        popper: {
                          sx: {
                            [`& .${tooltipClasses.tooltip}`]: {
                              backgroundColor: (theme) =>
                                theme.palette.common.black,
                            },
                          },
                        },
                      }}
                      title={txn?.beneficiary_account_bank_acc_no}
                    >
                      <div className="flex justify-between items-center w-full text-[#F9F9F9] my-[10px]">
                        <span>
                          {txn?.owner_account_bank_acc_no &&
                            txn.owner_account_bank_acc_no.substring(0, 4) +
                              " •••• " +
                              txn.owner_account_bank_acc_no.substring(
                                txn.owner_account_bank_acc_no.length - 4
                              )}
                        </span>
                        <img
                          src={ArrowRightBlue}
                          alt="Arrow"
                          className="mx-2 w-4 h-4"
                        />

                        <span className="text-right pr-4">
                          {txn?.beneficiary_account_bank_acc_no &&
                            txn.beneficiary_account_bank_acc_no.substring(
                              0,
                              4
                            ) +
                              " •••• " +
                              txn.beneficiary_account_bank_acc_no.substring(
                                txn.beneficiary_account_bank_acc_no.length - 4
                              )}
                        </span>
                      </div>
                    </Tooltip>
                    <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_top_date">
                      <p className="w-[77px] h-[14px] text-left text-[10px] font-semibold leading-[13.62px] text-[#DADADA]w-[76px] tracking-[0px] text-[#b0bbce] opacity-100 whitespace-nowrap">
                        {TimestampConverter(txn?.created_on)}
                      </p>
                      <Button
                        onClick={() => {
                          navigate(
                            `/transaction/internal_transaction/details/${txn.id}`
                          );
                        }}
                        className="moreDetailsBottom"
                      >
                        <h6>More details</h6>
                        <img src={RightArrow} className="mt-1" />
                      </Button>
                    </div>
                  </div>
                  <div className="multiLowerBottom">
                    <div className="refreshBottom">
                      <div className="Owner_Balance">
                        <div className="name1 text-xs">
                          <h4
                            style={{
                              color:
                                txn?.owner_account_balance >= txn?.amount
                                  ? "#4DDD37"
                                  : "#FF5860",
                            }}
                          >
                            ₹
                            {
                              <FormatAmount
                                price={txn?.owner_account_balance}
                              />
                            }
                          </h4>
                        </div>
                        <div className="balanceTitle">
                          <p>
                            <AnimationUpAndDown
                              loader={createTransactionBankRefreshLoading}
                              currentId={txn?.owner_account_id}
                              currentBalance={txn?.owner_account_balance}
                              previousId={refreshAnimation}
                              previousBalance={PrevBalance}
                              refreshType={"single"}
                              classType={"MultiApproval"}
                            />
                          </p>
                        </div>
                      </div>
                      <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title="Refresh Balance"
                        >
                          <button
                            className="multiRefreshButton"
                            onClick={(e) =>
                              handleRefresh(
                                e,
                                txn?.owner_account_id,
                                txn?.txn_id,
                                txn?.owner_account_balance
                              )
                            }
                          >
                            {createTransactionBankRefreshLoading &&
                            refreshId === txn?.txn_id ? (
                              <BalanceLoader
                                loader={createTransactionBankRefreshLoading}
                                type={"createTxn"}
                              />
                            ) : !createTransactionBankRefreshLoading &&
                              refreshId === txn?.txn_id ? (
                              <BalanceLoader
                                loader={createTransactionBankRefreshLoading}
                                setAccId={setRefreshId}
                                type={"createTxn"}
                              />
                            ) : (
                              <>
                                {" "}
                                <img src={WhiteReload} />
                                <h6 className="text-[10px]">Refresh</h6>
                              </>
                            )}
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="multiSelctBottomContainer">
                      <div className="balanceText">
                        <h5 className="whitespace-nowrap w-[140px] overflow-ellipsis overflow-hidden text-[10px]">
                          Balance -{" "}
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title={
                              txn?.owner_account_balance_last_fetched_at_in_words
                            }
                          >
                            {
                              txn?.owner_account_balance_last_fetched_at_in_words
                            }
                          </Tooltip>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {isApprovingTxn && (
            <div
              className="w-full flex  p-[30px] pt-2 gap-1 bg-[#AE9041] opacity-100 h-[80px]"
              style={{
                position: "absolute",
                bottom: "40px",
              }}
            >
              <img
                className="w-[30px] h-[30px]"
                src={whiteLoader}
                alt="whiteLoader"
              />
              <p className=" text-left text-[16px] mt-[2px]  font-normal tracking-[0px] text-white opacity-100">
                Approving transactions
              </p>
            </div>
          )}
          <div
            style={{
              position: "absolute",
              bottom: "0px",
            }}
            className="h-[74px] flex w-full justify-around items-center bg-[#506994] shadow-[0px_-3px_6px_rgba(0,0,0,0.29)] rounded-t-[40px] opacity-100"
          >
            {loading ? (
              <>
                <img src={LoaderIcon} alt="Loading" className="w-10 h-10" />
              </>
            ) : (
              <>
                <button
                  onClick={() => handleOpenPopup(transactionFilterID)}
                  className=" flex items-center justify-center gap-2 w-[145px] h-[39px] bg-[#FF5860] rounded-[44px] opacity-100"
                >
                  <img
                    className="w-[16px] h-[16px]"
                    src={RejectedWhite}
                    alt="rejected_white"
                  />
                  <p className="  font-semibold text-[14px]  text-white opacity-100">
                    Reject
                  </p>
                </button>
                <button
                  onClick={() => handleTxnApproval(transactionFilterID)}
                  className="flex items-center gap-2 justify-center w-[145px] h-[39px] bg-[#69B76F] rounded-[43px] opacity-100"
                >
                  <img
                    className="w-[16px] h-[16px]"
                    src={ApproveWhite}
                    alt="approve_white"
                  />
                  <p className="  font-semibold text-[14px]  text-white opacity-100">
                    Approve
                  </p>
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div
          className="multiTxnDetailsMain"
          style={{
            overflow: showPopup ? "hidden" : "",
            height: showPopup ? "100vh" : "",
          }}
        >
          {showMultiTxnDetailPop && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-custom-backdrop bg-opacity-80">
              <InternalTxnDetailPagePopup
                closeInternalTxnDetailModal={closeInternalTxnDetailModal}
                internal_txn_id={txnId}
                type={"internal_txn_detail_modal"}
                setShowInternalTxnDetailPop={setShowMultiTxnDetailPop}
                error_msg={errorMessage}
                setErrorMessage={setErrorMessage}
              />
            </div>
          )}
          {loading && (
            <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom-backdrop bg-opacity-80">
              <div className="flex justify-center items-center w-[204px] h-[90px] opacity-100">
                <img
                  className="w-[60px] h-[60px]"
                  src={LoaderIcon}
                  alt="Loading..."
                />
              </div>
            </div>
          )}
          <div className="multiTxnContainer">
            <div className="multiSelectTopContainer">
              {showPopup && (
                <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-custom-backdrop bg-opacity-80">
                  <InternalDeclinePopup
                    onClose={handleClosePopup}
                    accountId={transactionFilterID}
                    allAccounts={transactionFilterData}
                    updateAllAccounts={updateAllAccounts}
                    status={"multiTransaction"}
                    sideDrawer={"multiApproval"}
                  />
                </div>
              )}
              <Button
                onClick={() => navigate(-1)}
                className="multiSelectCancelBtn multiBtnCommon"
              >
                <img src={Close} />
                <p className="text-[#f9f9f9] text-[10px]">Cancel</p>
              </Button>
              <div className="multiAmountOuter">
                <p className="multiSelectAmountInner">
                  ₹ {<FormatAmount price={total} />}
                </p>
                <p className="multiSelectTextInner">Transaction amount</p>
              </div>
              <div className="w-[204px] flex h-[90px] bg-[#506994]">
                {loading && (
                  <div className="flex justify-center items-center w-[204px] h-[90px] opacity-100">
                    <img
                      className="w-[40px] h-[40px]"
                      src={LoaderIcon}
                      alt="Loading..."
                    />
                  </div>
                )}
                {!loading && (
                  <>
                    <Button
                      onClick={() => {
                        if (transactionFilterData.length !== 0) {
                          handleOpenPopup(transactionFilterID);
                        }
                      }}
                      className={`multiSelectRejectBtn multiBtnCommon ${
                        transactionFilterData.length !== 0
                          ? "multiSelectRejectBtnDisabled"
                          : ""
                      }`}
                      style={{
                        cursor:
                          transactionFilterData.length !== 0
                            ? "pointer"
                            : "default",
                        opacity:
                          transactionFilterData.length !== 0 ? "1" : "0.6",
                      }}
                    >
                      <img src={Decline} alt="Loading..." />
                      <p className="text-[#F9F9F9]">Reject</p>
                    </Button>
                    <Button
                      className={`multiSelectApproveBtn multiBtnCommon ${
                        transactionFilterData.length !== 0
                          ? "multiSelectApproveBtnDisabled"
                          : ""
                      }`}
                      style={{
                        cursor:
                          transactionFilterData.length !== 0
                            ? "pointer"
                            : "default",
                        opacity:
                          transactionFilterData.length !== 0 ? "1" : "0.6",
                      }}
                      onClick={() => {
                        if (transactionFilterData.length !== 0) {
                          handleTxnApproval(transactionFilterID);
                        }
                      }}
                    >
                      <img src={Approved} alt="Loading..." />
                      <p className="text-[#F9F9F9]">Approve</p>
                    </Button>
                  </>
                )}
              </div>
            </div>
            <div className="multiSelectPostApprovalContainer">
              <div>
                <p className="multiSelectPostApprovalText">
                  Post Approval Process
                </p>
                <p className="insufficientMsg text-xs">
                  Once approved, transaction distribution will get created and
                  auto settled using selected source account. If there are
                  insufficient funds, the transaction will settle once the
                  source account is refilled, and distribution will be initiated
                  thereafter.
                </p>
              </div>
              {/* {isInsufficientFunds && (
                <div className="w-[306px] h-full bg-[#667CA3] flex  items-center gap-5">
                  <img
                    src={addStatus}
                    alt=""
                    width={30}
                    height={30}
                    className="ml-[20px]"
                  />
                  <p className="w-[218px] h-[68px] text-left font-normal text-[12px] leading-[17px] text-[#DADADA]">
                    Upon approval, distribution will not be initiated for one or
                    more transactions due to insufficient balance in the
                    associated source account.
                  </p>
                </div>
              )} */}
            </div>
          </div>
          <div className="multiSelectDataCard">
            <h4>Selected Transaction ({transactionFilterData?.length})</h4>
            <div className="multiSelctedTxnMainContainer">
              {transactionFilterData?.map((txn, i) => {
                return (
                  <div key={i} className="multiSelectApprovalContainer">
                    <div className="mutliSelectCardTop min-w-0">
                      <div className="flex justify-between items-center w-full">
                        <h4 className="whitespace-nowrap text-base amount-style">
                          ₹ <FormatAmount price={txn?.amount} />
                        </h4>

                        {txn?.confidential_transaction === "Y" && (
                          <span>
                            <img
                              className="w-[28px] h-[22px] pr-2.5"
                              src={confidential_transactionImg}
                              alt="confidential"
                            />
                          </span>
                        )}
                      </div>

                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={txn?.beneficiary_account_name
                          .toLowerCase()
                          .split(" ")
                          .map(function (word) {
                            return word.charAt(0).toUpperCase() + word.slice(1);
                          })
                          .join(" ")}
                      >
                        <div className="flex justify-between items-center w-full text-[#F9F9F9]">
                          <span className="text-sm">
                            {" ••••  " +
                              txn?.owner_account_bank_acc_no?.substring(
                                txn?.owner_account_bank_acc_no?.length - 4
                              )}
                          </span>

                          <img
                            src={ArrowRightBlue}
                            alt="Arrow"
                            className="mx-2 w-4 h-4"
                          />
                          <span className="text-right text-sm pr-4">
                            {" ••••  " +
                              txn?.beneficiary_account_bank_acc_no?.substring(
                                txn?.beneficiary_account_bank_acc_no?.length - 4
                              )}
                          </span>
                        </div>
                      </Tooltip>
                      {/* {transactionFilterData?.confidential_transaction ===
                      "Y" ? (
                        <span>
                          <img
                            className="w-[16px] h-[16px]"
                            src={confidential_transactionImg}
                            alt="confidential"
                          />
                        </span>
                      ) : (
                        <></>
                      )} */}
                      <div className="multiSelectBottomDate">
                        <p>{TimestampConverter(txn?.created_on)}</p>
                        <Button
                          onClick={(e) => {
                            openMultiTxnDetailModal();
                            setTxnId(txn?.txn_id);
                          }}
                          className="moreDetailsBottom"
                        >
                          <h6>More details</h6>
                          <img src={RightArrow} className="mt-[3px]" />
                        </Button>
                      </div>
                    </div>
                    <div className="multiLowerBottom">
                      <div className="refreshBottom">
                        <div className="Owner_Balance">
                          <div className=" text-xs">
                            <h4
                              style={{
                                color:
                                  txn?.owner_account_balance >= txn?.amount
                                    ? "#4DDD37"
                                    : "#FF5860",
                              }}
                            >
                              ₹
                              {
                                <FormatAmount
                                  price={txn?.owner_account_balance}
                                />
                              }
                            </h4>
                          </div>
                          <div className="balanceTitle">
                            <p>
                              <AnimationUpAndDown
                                loader={createTransactionBankRefreshLoading}
                                currentId={txn?.owner_account_id}
                                currentBalance={txn?.owner_account_balance}
                                previousId={refreshAnimation}
                                previousBalance={PrevBalance}
                                refreshType={"single"}
                                classType={"MultiApproval"}
                              />
                            </p>
                          </div>
                        </div>
                        <div className="beneficiaryMultiApprovalTxnDetails_bottom_data_bottom_2">
                          <Tooltip
                            componentsProps={{
                              popper: {
                                sx: {
                                  [`& .${tooltipClasses.tooltip}`]: {
                                    backgroundColor: (theme) =>
                                      theme.palette.common.black,
                                  },
                                },
                              },
                            }}
                            title="Refresh Balance"
                          >
                            <button
                              className="multiRefreshButton"
                              onClick={(e) =>
                                handleRefresh(
                                  e,
                                  txn?.owner_account_id,
                                  txn?.txn_id,
                                  txn?.owner_account_balance
                                )
                              }
                            >
                              {createTransactionBankRefreshLoading &&
                              refreshId === txn?.txn_id ? (
                                <BalanceLoader
                                  loader={createTransactionBankRefreshLoading}
                                  type={"createTxn"}
                                />
                              ) : !createTransactionBankRefreshLoading &&
                                refreshId === txn?.txn_id ? (
                                <BalanceLoader
                                  loader={createTransactionBankRefreshLoading}
                                  setAccId={setRefreshId}
                                  type={"createTxn"}
                                />
                              ) : (
                                <>
                                  {" "}
                                  <img src={WhiteReload} />
                                  <h6 className="text-[10px]">Refresh</h6>
                                </>
                              )}
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="multiSelctBottomContainer">
                        <div className="balanceText">
                          <h5 className="whitespace-nowrap w-[151px] overflow-ellipsis overflow-hidden text-[10px]">
                            Balance -{" "}
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                txn?.owner_account_balance_last_fetched_at_in_words
                              }
                            >
                              {
                                txn?.owner_account_balance_last_fetched_at_in_words
                              }
                            </Tooltip>
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InternalMultiApprovalTxnDetails;
