import React from "react";
import "./index.css";
import Close from "../../../assets/CloseIcons/close_with_blue_square.svg";
import { QRCodeSVG } from "qrcode.react";

const ScannerModel = ({ url, appType, close }) => {
  return (
    <div className="scanner_main">
      <div className="scanner_div">
        <div className="scanner_div_top">
          <h4>Scan QR code</h4>
          <img
            src={Close}
            alt="Close"
            onClick={() => {
              close(false);
            }}
          />
        </div>
        <div className="scanner_div_bottom_top">
          <div className="scanner_div_bottom">
            <QRCodeSVG value={url} size={200} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScannerModel;
