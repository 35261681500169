import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { toast_position } from "../Settings/Authorization";
import { env } from "../../../env";

const baseUrl = env.REACT_APP_BASE_URL;

export const internalTxnViewAll = createAsyncThunk(
  "internalTxnViewAll",
  async (values) => {
    const {
      page_no,
      page_item_count,
      amount,
      lifecycle_status,
      payment_method,
      beneficiary_account_id,
      owner_account_id,
      start_date,
      end_date,
      txn_id,
      entity_id,
      category,
      bank_identifier,
      distribution_lifecycle_status,
      additional_data,
      confidential_transaction,
      category_value,
      category_name
    } = values;
    try {
      const response = await axios.get(`${baseUrl}/transactions/internal`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
        params: {
          page_no: page_no,
          page_size: page_item_count,
          amount: amount,
          lifecycle_status: lifecycle_status,
          payment_method: payment_method,
          beneficiary_account_id: beneficiary_account_id,
          owner_account_id: owner_account_id,
          start_date: start_date,
          end_date: end_date,
          txn_id: txn_id,
          entity_id: entity_id,
          category: category,
          bank_identifier: bank_identifier,
          distribution_lifecycle_status: distribution_lifecycle_status,
          additional_data: additional_data,
          confidential_transaction:
            confidential_transaction === "Show only confidential"
              ? "Y"
              : confidential_transaction === "Hide confidential"
              ? "N"
                : undefined,
          category_name: category_name,
          category_value: category_value
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      throw error;
    }
  }
);

const internalTxnViewAllDataSlice = createSlice({
  name: "internalTxnViewAllData",
  initialState: {
    storedPage: 1,
    toggle: false,

    internalTxnViewAllAccData: null,
    internalTxnViewAllAccLoading: false,
    internalTxnViewAllAccError: null,
  },
  reducers: {
    setToggle: (state, action) => {
      state.toggle = action.payload;
    },
    resetToggle: (state) => {
      state.toggle = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(internalTxnViewAll.fulfilled, (state, action) => {
      state.internalTxnViewAllAccLoading = false;
      state.internalTxnViewAllAccData = action.payload;
    });
    builder.addCase(internalTxnViewAll.rejected, (state, action) => {
      state.internalTxnViewAllAccError = true;
      state.internalTxnViewAllAccData = null;
      state.internalTxnViewAllAccLoading = false;
    });
    builder.addCase(internalTxnViewAll.pending, (state, action) => {
      state.internalTxnViewAllAccLoading = true;
    });
  },
});

export const { setToggle, resetpage, setStoredPage, resetToggle } =
  internalTxnViewAllDataSlice.actions;

export default internalTxnViewAllDataSlice.reducer;
