import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Box, Button, Grid, Typography, Skeleton } from "@mui/material";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";
import {
  resetGetCinData,
  resetGetDinData,
  resetGetPanData,
} from "../../../redux/features/verifyPAN/verifyPAN";
import CinIcon from "../../../assets/VerificationIcons/cin_verification.svg";
import CloseBtn from "../../../assets/CloseIcons/close.svg";
import DeviceIcon from "../../../assets/GeneralIcons/devices_with_white_circle.svg";
import DinIcon from "../../../assets/VerificationIcons/din_verification.svg";
import GstIcon from "../../../assets/VerificationIcons/gst_verification.svg";
import IfscIcon from "../../../assets/IfscIcons/bank 1.svg";
import NotificationIcon from "../../../assets/GeneralIcons/notification.svg";
import PanIcon from "../../../assets/VerificationIcons/pan_verification.svg";
import SecurityIcon from "../../../assets/PasswordIcons/security_center.svg";
import SettingImg from "../../../assets/Graphics/User settings.svg";
import StatisticsIcon from "../../../assets/GeneralIcons/statistics.svg";
import TestMatrixIcon from "../../../assets/GeneralIcons/test_matrix.svg";
import UserIcon from "../../../assets/UserProfileIcons/Users.svg";
import VerificationArrow from "../../../assets/ArrowsIcons/gst_verification_arrow.svg";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";

function SkeletonChildrenDemo(props) {
  const { loading = false } = props;

  return (
    <div>
      <Box sx={{ width: "50%" }} style={{ marginLeft: "20px" }}>
        {loading && (
          <Skeleton width="100%">
            <Typography>.</Typography>
          </Skeleton>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          {loading && (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          {loading && (
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          {loading && (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          {loading && (
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ margin: 1 }}>
          {loading && (
            <Skeleton variant="circular">
              <Avatar />
            </Skeleton>
          )}
        </Box>
        <Box sx={{ width: "100%" }}>
          {loading && (
            <Skeleton width="100%">
              <Typography>.</Typography>
            </Skeleton>
          )}
        </Box>
      </Box>
    </div>
  );
}

SkeletonChildrenDemo.propTypes = {
  loading: PropTypes.bool,
};

const SettingsDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
    dispatch(checkUsertoken());
    dispatch(resetGetCinData());
    dispatch(resetGetDinData());
    dispatch(resetGetPanData());
  }, [dispatch]);

  const isMobile = useMediaQuery({ maxWidth: 800 });

  const handleNavigate = async (type, requiredService, route) => {
    const noToastRoutes = [
      "/settings/IFSC",
      "/settings/security_center",
      "/settings/CIN_Verification",
      "/settings/DIN_Verification",
    ];

    if (isMobile && !noToastRoutes.includes(route)) {
      toast.info("Coming soon!", toast_position);
      return;
    }

    if (!services?.includes(requiredService)) {
      await dispatch(checkUsertoken());
      navigate("/feature_not_assigned", {
        state: { unavailableService: requiredService },
      });
    } else {
      await dispatch(checkUsertoken());
      navigate(route);
    }
  };

  const buttonConfig = [
    {
      title: "ENTERPRISE DATA",
      items: [
        // {
        //   label: "Statistics",
        //   icon: StatisticsIcon,
        //   route: null,
        //   comingSoon: true,
        // },
        {
          label: "Users",
          icon: UserIcon,
          route: "/settings/Users",
          requiredService: services?.includes("Admin") ? "MimicEntity" : "VEmp",
        },
        {
          label: "Devices",
          icon: DeviceIcon,
          route: "/settings/Devices",
          requiredService: "Sup_VDevice",
        },
      ],
    },
    {
      title: "SET UP",
      items: [
        {
          label: "Security Center",
          icon: SecurityIcon,
          route: "/settings/security_center",
          requiredService: "PLogin",
          // route: null,
          // comingSoon: true,
        },
        // {
        //   label: "Test Matrix",
        //   icon: TestMatrixIcon,
        //   route: null,
        //   comingSoon: true,
        // },
        // {
        //   label: "Notification Center",
        //   icon: NotificationIcon,
        //   route: null,
        //   comingSoon: true,
        // },
      ],
    },

    {
      title: "VERIFICATION",
      items: [
        {
          label: "CIN Verification",
          icon: CinIcon,
          route: "/settings/CIN_Verification",
          requiredService: "VCIN",
        },
        {
          label: "DIN Verification",
          icon: DinIcon,
          route: "/settings/DIN_Verification",
          requiredService: "VDIN",
        },
        {
          label: "PAN Verification",
          icon: PanIcon,
          route: "/settings/PAN_Verification",
          requiredService: "VPAN",
        },
        {
          label: "GST Verification",
          icon: GstIcon,
          route: "/settings/GST_Verification",
          requiredService: "VGSTIN",
        },
        {
          label: "IFSC Verification",
          icon: IfscIcon,
          route: "/settings/IFSC",
          requiredService: "VIFSC",
        },
      ],
    },
  ];

  const renderButtons = (buttons) =>
    buttons.map((button, buttonIndex) => {
      const { label, icon, route, requiredService, comingSoon } = button;
      const isLastButton = buttonIndex === buttons.length - 1;
      return (
        <>
          <Button
            key={label}
            className="cardContainerSettings relative pt-1"
            style={{
              opacity: requiredService
                ? services?.includes(requiredService) ||
                  services?.includes("Admin")
                  ? "1"
                  : "0.5"
                : "1",
              // borderBottom: isLastButton ? "none" : "1px solid #DADADA",
              cursor: requiredService
                ? services?.includes(requiredService) ||
                  services?.includes("Admin")
                  ? "pointer"
                  : "not-allowed"
                : "pointer",
            }}
            onClick={() => {
              if (comingSoon) {
                toast.info("Coming Soon", toast_position);
              } else {
                handleNavigate(label, requiredService, route);
              }
            }}
          >
            <div className="contentDataSettings">
              <img alt={label} src={icon} />
              <h6 className="text-[#F9F9F9]">{label}</h6>
            </div>
            <img
              alt="arrowright"
              src={VerificationArrow}
              className="w-16 md:w-32 lg:w-48"
            />
          </Button>
          <div
            className="lineSepDiv"
            style={{
              marginLeft: "15px",
              // width: "228px",
              borderTop: "1px solid #DADADA",
              display: isLastButton ? "none" : "block",
            }}
          ></div>
        </>
      );
    });

  return (
    <div className="min-h-screen max-w-[1024px] text-white flex gap-4 mx-auto pb-5">
      <div
        className="lg:hidden fixed w-full top-0 bg-[#3A5686] h-[56px] z-50 flex items-center cursor-pointer"
        onClick={() => navigate(-1)}
      >
        <img src={CloseBtn} alt="Close" className="w-4 h-4 m-5" />
        <h1 className="text-base flex-1 text-[#F9F9F9]">Settings</h1>
      </div>

      <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 sm:grid-cols-1 max-w-screen-lg mx-auto mt-[80px] lg:mt-[146px] sm:mt-[85px] md:mt-[140px] px-[15px] h-44">
        {loading ? (
          <>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <SkeletonChildrenDemo loading />
              </Grid>
              <Grid item xs={12}>
                <SkeletonChildrenDemo />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <SkeletonChildrenDemo loading />
              </Grid>
              <Grid item xs={12}>
                <SkeletonChildrenDemo />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid item xs={12}>
                <SkeletonChildrenDemo loading />
              </Grid>
              <Grid item xs={12}>
                <SkeletonChildrenDemo />
              </Grid>
            </Grid>
          </>
        ) : (
          buttonConfig.map((data, index) => (
            <div key={index} className="pb-5">
              <h2 className="text-custom-pink text-base font-semibold mb-[20px] pl-[10px]">
                {data.title}
              </h2>
              <div className="flex flex-col gap-1.5">
                {renderButtons(data.items)}
              </div>
            </div>
          ))
        )}
      </div>

      <div className="hidden sm:block sm:w-1/2 lg:w-[35%] w-1/3 max-w-screen-lg mx-auto mt-[195px] lg:mt-[146px] sm:mt-[85px] md:mt-[140px] pr-4">
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <img src={SettingImg} alt="Settings" className="w-full" />
        )}
      </div>
    </div>
  );
};

export default SettingsDashboard;
