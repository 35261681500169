import React, { useEffect, useState } from "react";
import "./index.css";
import { checkUsertoken } from "../../../redux/features/login/login";
import { beneCategories } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InternalTxnSelectAccount from "../../../components/InternalTransactionMain/InternalTxnSelectAccount";
import InternalTxnAmount from "../../../components/InternalTransactionMain/InternalTxnAmount";
import InternalTxnConfirm from "../../../components/InternalTransactionMain/InternalTxnConfirm";
import { useCustomHook } from "./useCustomHook";
import StepperTxn from "../../../components/InternalTransactionMain/InternalTxnStepper/StepperTxn";
import ArrowLeft from "../../../assets/CreateTransactionIcons/arrowleft.png";

const InternalTransactionCreate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentAccountDetails = location?.state;
  const [smallSearch, setSmallSearch] = useState(false);
  const [smallSearchNonActive, setSmallSearchNonActive] = useState(false);
  const [additionalData, setAdditionalData] = useState({});
  const [selectBankNonActive, setSelectBankNonActive] = useState(false);
  const [selectBank, setSelectBank] = useState(false);
  const [associateModalOpen, setAssociateModalOpen] = useState(false);
  const [openOwner, setOpenOwner] = useState(false);
  const [refreshId, setRefreshId] = useState(true);
  const [selectSortAmt, setSelectSortAmt] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountModalSearch, setAccountModalSearch] = useState(false);
  const [transactionDisable, setTransactionDisable] = useState(false);
  const [isConnectedSelected, setIsConnectedSelected] = useState(true);
  const [isNeft, setIsNeft] = useState(false);
  const [isRtgs, setIsRtgs] = useState(false);
  const [isImps, setIsImps] = useState(false);
  const [comments, setComments] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [slideAnimation, setSlideAnimation] = useState(false);
  const [direction, setDirection] = useState(null);
  const [removeClassName, setRemoveClassName] = useState(true);
  const [amountInWords, setAmountInWords] = useState("");
  const [isConfidential, setIsConfidential] = useState(false);

  const [selectedValue, setSelectedValue] = useState({
    from: "Select",
    to: "Select",
  });

  const [selectedValueMonth, setSelectedValueMonth] = useState({
    month: "Select",
    year: "Select",
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRangeEnabled, setDateRangeEnabled] = useState(false);
  const [quantity, setQuantity] = useState("");

  const [showDatePickerCal, setShowDatePickerCal] = useState(false);
  const [startDateCal, setStartDateCal] = useState("");
  const [isSingleDateSelected, setIsSingleDateSelected] = useState(false);
  const [showRupeeSymbol, setShowRupeeSymbol] = useState(false);
  const [hyperLink, setHyperLink] = useState("");
  const [isStepper, setIsStepper] = useState(window.innerWidth <= 800);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [files, setFiles] = useState([]);
  const [showImages, setShowImages] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setIsStepper(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const [txnPayLoad, setTxnPayLoad] = useState({
    bankAccountName: currentAccountDetails?.bank_account_holder_full_name
      ? currentAccountDetails.bank_account_holder_full_name
      : "",
    beneAccountId: currentAccountDetails?.id ? currentAccountDetails?.id : null,
    bankAccountIFSC: currentAccountDetails?.ifsc_code
      ? currentAccountDetails?.ifsc_code
      : "",
    bankAccountNo: currentAccountDetails?.bank_acc_no
      ? currentAccountDetails?.bank_acc_no
      : "",
    amount: "",
    settleType: "",
    bankLogo: currentAccountDetails?.bank_name
      ? currentAccountDetails?.bank_name
      : "",

    pan: currentAccountDetails?.pan ? currentAccountDetails?.pan : "",

    ownerId: null,
    ownerBankIFSC: null,
    ownerBankBalance: 0,
    ownerLastUpdated: null,

    ownerBankLogo: null,
    ownerBankAccountNo: null,
    ownerBankAccountName: null,
    selectedCategory: null,
    mergeCategory: null,
    amountWords: "",
    destinationAccountBalance: null,
    destinationAcountOwnerId: null,
    isDestinationOwnerActive: null,
    destinationAcountOwnerLastUpdated: null,
  });
  const [filterPayload, setFilterPayload] = useState({
    pagination: 0,
    accNoInput: "",
    currentPage: 1,
    sortBank: "",
    sortAccountNo: "",
    bankNameInput: "",
    currentPageText: "01",
    beneAccountSearch: "",
    searchTerm: "",
  });
  const [filterPayloadNonActive, setFilterPayloadNonActive] = useState({
    pagination: 0,
    accNoInput: "",
    currentPage: 1,
    sortBank: "",
    sortAccountNo: "",
    bankNameInput: "",
    currentPageText: "01",
    searchTerm: "",
  });
  const [nextButtonEnabled, BenEnableNextButton] = useState(false);
  const steps = [
    "Select source account and destination account",
    "Enter amount",
    "Confirm and create transaction",
  ];
  const [isMounted, setIsMounted] = useState(true);
  const { shouldRender, animationClass } = useCustomHook(
    isMounted,
    270,
    direction
  );
  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneCategories());
  }, [dispatch]);

  const isValidHttpUrl = (url) => {
    const httpRegex =
      /^(https?:\/\/)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/;
    return httpRegex.test(url);
  };

  const isActive = activeStep === 1;
  useEffect(() => {
    if (
      activeStep === 0 &&
      (txnPayLoad.beneAccountId === null || txnPayLoad?.ownerId === null)
    ) {
      BenEnableNextButton(false);
    } else if (activeStep === 1 && !txnPayLoad?.amount) {
      BenEnableNextButton(false);
    } else if (activeStep === 2 && !txnPayLoad?.settleType) {
      BenEnableNextButton(false);
    } else {
      if (isActive) {
        if (
          txnPayLoad.selectedCategory?.configuration?.data?.some(
            (val) => val?.mandatory === "Y"
          )
        ) {
          const mandatoryFieldsCount =
            txnPayLoad.selectedCategory?.configuration?.data?.filter(
              (val) => val?.mandatory === "Y"
            )?.length;

          const mandatoryFieldsTypedCount = txnPayLoad?.mergeCategory?.filter(
            (val) => val?.mandatory === "Y" && val?.value
          )?.length;

          const isHyperlinkHave = txnPayLoad?.mergeCategory?.some(
            (val) => val?.data_type === "hyperlink" && val?.mandatory === "Y"
          );

          const isHyperlink = txnPayLoad?.mergeCategory?.some(
            (val) =>
              val?.data_type === "hyperlink" && isValidHttpUrl(val?.value)
          );

          const isAnyMandatoryFieldNotEmpty =
            mandatoryFieldsCount === mandatoryFieldsTypedCount;
          const isAmountFieldNotEmpty =
            txnPayLoad?.amount > 0 && txnPayLoad?.amount !== "";
          let check;

          if (isHyperlinkHave) {
            check =
              isAnyMandatoryFieldNotEmpty &&
              isAmountFieldNotEmpty &&
              isHyperlink;
          } else {
            check = isAnyMandatoryFieldNotEmpty && isAmountFieldNotEmpty;
          }

          BenEnableNextButton(check);
        } else if (
          (txnPayLoad?.amount && txnPayLoad?.amount > 0) ||
          (txnPayLoad?.amount && txnPayLoad?.amount !== "")
        ) {
          BenEnableNextButton(true);
        } else {
          BenEnableNextButton(false);
        }
      } else if (
        txnPayLoad?.settleType === "imps" &&
        txnPayLoad?.amount > 200000 &&
        activeStep === 2
      ) {
        BenEnableNextButton(false);
      } else if (
        txnPayLoad?.settleType === "rtgs" &&
        txnPayLoad?.amount < 200000 &&
        activeStep === 2
      ) {
        BenEnableNextButton(false);
      } else {
        BenEnableNextButton(true);
      }
    }
  }, [activeStep, txnPayLoad]);

  const toggleMounting = () => {
    setIsMounted(false);
    setTimeout(() => {
      setIsMounted(true);
    }, 270);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setDirection("next");
    toggleMounting();
    setRemoveClassName(false);
    setTimeout(() => {
      setRemoveClassName(true);
    }, 1000);
    setTimeout(() => {
      setDirection(null);
    }, 500);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setDirection("previous");
    toggleMounting();
    setRemoveClassName(false);
    setTimeout(() => {
      setRemoveClassName(true);
    }, 1000);
    setTimeout(() => {
      setDirection(null);
    }, 500);
  };

  const { isTransCateLoading, beneTransCategories, isTransCateError } =
    useSelector((state) => state.beneAllVerification);

  useEffect(() => {
    setTxnPayLoad({
      ...txnPayLoad,
      selectedCategory: beneTransCategories?.categories?.filter((fil) => {
        return fil.name === "Internal";
      })[0],
      mergeCategory: beneTransCategories?.categories?.filter((fil) => {
        return fil.name === "Internal";
      })[0]?.configuration?.data,
    });
  }, [beneTransCategories]);

  const renderBillerForms = () => {
    switch (activeStep) {
      case 0:
        return (
          <InternalTxnSelectAccount
            slideAnimation={slideAnimation}
            setSlideAnimation={setSlideAnimation}
            title="Destination Account Details"
            txnPayLoad={txnPayLoad}
            setTxnPayLoad={setTxnPayLoad}
            BenEnableNextButton={BenEnableNextButton}
            activeStep={activeStep}
            associateModalOpen={associateModalOpen}
            setAssociateModalOpen={setAssociateModalOpen}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            filterPayload={filterPayload}
            setFilterPayload={setFilterPayload}
            accountModalSearch={accountModalSearch}
            setAccountModalSearch={setAccountModalSearch}
            setOpenOwner={setOpenOwner}
            setIsNeft={setIsNeft}
            setIsImps={setIsImps}
            setIsRtgs={setIsRtgs}
            flow={"createTxn"}
            direction={direction}
            setDirection={setDirection}
            selectBank={selectBank}
            setSelectBank={setSelectBank}
            filterPayloadNonActive={filterPayloadNonActive}
            setFilterPayloadNonActive={setFilterPayloadNonActive}
            smallSearch={smallSearch}
            setSmallSearch={setSmallSearch}
            selectSortAmt={selectSortAmt}
            setSelectSortAmt={setSelectSortAmt}
            isConnectedSelected={isConnectedSelected}
            setIsConnectedSelected={setIsConnectedSelected}
            BenEnableNextButton1={BenEnableNextButton}
            isNeft={isNeft}
            isRtgs={isRtgs}
            isImps={isImps}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            openOwner={openOwner}
            selectBankNonActive={selectBankNonActive}
            setSelectBankNonActive={setSelectBankNonActive}
            smallSearchNonActive={smallSearchNonActive}
            setSmallSearchNonActive={setSmallSearchNonActive}
            isConfidential={isConfidential}
            setIsConfidential={setIsConfidential}
          />
        );
      case 1:
        return (
          <InternalTxnAmount
            txnPayLoad={txnPayLoad}
            setTxnPayLoad={setTxnPayLoad}
            additionalData={additionalData}
            setAdditionalData={setAdditionalData}
            BenEnableNextButton={BenEnableNextButton}
            nextButtonEnabled={nextButtonEnabled}
            activeStep={activeStep}
            comments={comments}
            setComments={setComments}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            selectedValueMonth={selectedValueMonth}
            setSelectedValueMonth={setSelectedValueMonth}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
            dateRangeEnabled={dateRangeEnabled}
            setDateRangeEnabled={setDateRangeEnabled}
            showDatePickerCal={showDatePickerCal}
            setShowDatePickerCal={setShowDatePickerCal}
            startDateCal={startDateCal}
            setStartDateCal={setStartDateCal}
            isSingleDateSelected={isSingleDateSelected}
            setIsSingleDateSelected={setIsSingleDateSelected}
            quantity={quantity}
            setQuantity={setQuantity}
            amountInWords={amountInWords}
            setAmountInWords={setAmountInWords}
            showRupeeSymbol={showRupeeSymbol}
            setShowRupeeSymbol={setShowRupeeSymbol}
            hyperLink={hyperLink}
            setHyperLink={setHyperLink}
            direction={direction}
            setDirection={setDirection}
            files={files}
            setFiles={setFiles}
            showImages={showImages}
            setShowImages={setShowImages}
          />
        );

      case 2:
        return (
          <InternalTxnConfirm
            txnPayLoad={txnPayLoad}
            setTxnPayLoad={setTxnPayLoad}
            selectedAccountId={selectedAccountId}
            additionalData={additionalData}
            amountWords={txnPayLoad.amountWords}
            comments={comments}
            setComments={setComments}
            direction={direction}
            setDirection={setDirection}
            setRefreshId={setRefreshId}
            refreshId={refreshId}
            isConfidential={isConfidential}
            setIsConfidential={setIsConfidential}
            files={files}
            showImages={showImages}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="internal_Container_txn">
      <div className="Internal_stepper_Div">
        <StepperTxn
          activeStep={activeStep}
          handleBack={handleBack}
          steps={steps}
          handleNext={handleNext}
          nextButtonEnabled={nextButtonEnabled}
          payload={txnPayLoad}
          direction={direction}
          isConfidential={isConfidential}
          files={files}
        />
      </div>
      {isStepper && (
        <>
          <div className="flex items-center w-[100vw] bg-[#3A5686] p-3 fixed top-0 z-20">
            <img
              src={ArrowLeft}
              alt=""
              className="w-[20px] h-[20px] cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <div className="flex flex-col ml-2">
              <div className="text-[#f9f9f9] text-base">Create Transaction</div>
              <div className="text-[#f9f9f9] text-xs ">
                {steps[activeStep] || ""}
              </div>
            </div>
          </div>
        </>
      )}
      <div
        className={!isMobile ? "carousel-container" : "carousel-container-res"}
      >
        {shouldRender && <>{renderBillerForms()}</>}
      </div>
    </div>
  );
};

export default InternalTransactionCreate;
