import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Tooltip, tooltipClasses } from "@mui/material";
import SelectUser from "../../../assets/CreateTransactionIcons/Select account.png";
import EditAccount from "../../../assets/CreateTransactionIcons/editAccount.png";
import BeneTxnAccountModal from "../../BeneTransaction/BeneTxnAccount/BeneTxnAccountModal";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { beneLookUp } from "../../../redux/features/verifyPAN/verifyPAN";
import { ownerListViewAllAcc } from "../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { beneBankLookUp } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { formatAccountNumber } from "../../_utils/FormatAccountNumber";
import { checkUsertoken } from "../../../redux/features/login/login";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import InternalTxnSettleMethod from "../InternalTxnSettleMethod";
import arrow_right_blue from "../../../assets/ArrowsIcons/arrow_right_blue.svg";
import edit_white from "../../../assets/EditIcons/edit_white.svg";
import info_filled from "../../../assets/InfoIcons/info_filled.svg";
import arrow_right_orange from "../../../assets/ArrowsIcons/arrow_right_orange.svg";

const InternalTxnSelectAccount = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isToggled, setIsToggled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [accId, setAccId] = useState("");
  const [refreshId, setRefreshId] = useState("");
  const [openPopup, setOpenpopup] = useState(false);
  const [openAssociatedAccounts, setOpenAssociatedAccounts] = useState(false);
  const [openInternalAssociate, setOpenInternalAssociate] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 800);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  const openAssociatedAccountsFound = () => {
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: props?.txnPayLoad?.pan }));
    dispatch(
      beneBankLookUp({
        ifsc: props?.txnPayLoad?.bankAccountIFSC,
        bank_account_no: props?.txnPayLoad?.bankAccountNo,
        bank_account_holder_full_name: props?.txnPayLoad?.bankAccountName,
        // pan_number: props?.txnPayLoad?.pan,
      })
    );

    setOpenInternalAssociate(true);
    // toast.success("Under development", toast_position);
  };

  const closeAssociatedAccountsFound = () => {
    setOpenInternalAssociate(false);
  };

  const { ownerAccBalanceLoading } = useSelector(
    (state) => state.ownerAllVerification
  );

  const [beneAccountId, setBeneAccountId] = useState(
    props.txnPayLoad?.beneAccountId ? props.txnPayLoad?.beneAccountId : null
  );
  const [accountName, setAccountName] = useState(
    props.txnPayLoad?.bankAccountName ? props.txnPayLoad?.bankAccountName : ""
  );
  const [accountNumber, setAccountNumber] = useState(
    props.txnPayLoad?.bankAccountNo ? props.txnPayLoad?.bankAccountNo : ""
  );
  const [panNumber, setPanNumber] = useState(
    props.txnPayLoad?.pan ? props.txnPayLoad?.pan : null
  );
  const [IFSC, setIFSC] = useState(
    props.txnPayLoad?.bankAccountIFSC ? props.txnPayLoad?.bankAccountIFSC : ""
  );
  const [bankLogo, setBankLogo] = useState(
    props.txnPayLoad?.bankLogo ? props.txnPayLoad?.bankLogo : ""
  );
  const [category, setCategory] = useState(
    props.txnPayLoad?.categories ? props.txnPayLoad?.categories?.split(",") : []
  );

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [haveBankAssociate, setHaveBankAssociate] = useState(0);
  const [havePANAssociate, setHavePANAssociate] = useState(0);

  const unassignCategories = props.categories;

  const { beneLookData } = useSelector((state) => state.verify);

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );
  const { beneViewAllAccLoading, beneViewAllAccData, beneViewAllAccError } =
    useSelector((state) => state.beneAllVerification);
  const { isTransCateLoading, beneTransCategories, isTransCateError } =
    useSelector((state) => state.beneAllVerification);

  useEffect(() => {
    if (props?.activeStep === 0 && props?.selectedCategory === null) {
      props?.BenEnableNextButton(true);
    } else if (props?.activeStep === 0 && props?.selectedCategory !== null) {
      props?.BenEnableNextButton(false);
    }
  }, [props?.activeStep]);

  const handleUnassignNavigate = (id) => {
    navigate(`/accounts/beneficiary_account/edit/categories/${id}`);
  };

  const handleOpenModal = () => {
    props.setIsModalOpen(true);
    props?.setDirection(null);
  };

  const handleCloseModal = () => {
    props.setIsModalOpen(false);
  };

  const handleCloseModal1 = () => {
    setOpenInternalAssociate(false);
  };

  const handleRefresh = () => {
    setRefreshId(beneAccountId);
  };

  useEffect(() => {
    setHaveBankAssociate(bankData?.total_count || 0);
    setHavePANAssociate(beneLookData?.total_count || 0);
  }, [beneLookData, bankData, dispatch]);

  const handleAccountSelection = async (
    accountName,
    accountNumber,
    ifsc,
    bankLogo,
    category,
    panNo,
    beneAccountId,
    isInternal
  ) => {
    const categoriesArray = category?.split(",");
    setCategory(categoriesArray);
    setAccountName(accountName);
    setAccountNumber(accountNumber);
    setIFSC(ifsc);
    setBankLogo(bankLogo);
    setBeneAccountId(beneAccountId);
    setPanNumber(panNo);
    setSelectedCategory(null);
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: panNo }));
    dispatch(
      beneBankLookUp({
        ifsc: ifsc,
        bank_account_no: accountNumber,
      })
    );

    let data1 = await dispatch(
      ownerListViewAllAcc({
        bank_account_no: accountNumber,
        pan: panNo,
        ifsc: ifsc,
      })
    );

    props.setTxnPayLoad({
      ...props.txnPayLoad,
      bankAccountName: accountName,
      beneAccountId: beneAccountId,
      bankAccountIFSC: ifsc,
      bankAccountNo: accountNumber,
      bankLogo: bankLogo,
      pan: panNo,
      isDestinationOwnerActive: isInternal,
      destinationAccountBalance:
        data1?.payload?.owner_accounts[0]?.owner_account_balance,
      destinationAcountOwnerId: data1?.payload?.owner_accounts[0]?.id,
      destinationAcountOwnerLastUpdated:
        data1?.payload?.owner_accounts[0]
          ?.owner_account_balance_last_fetched_at_in_words,
      selectedCategory: beneTransCategories?.categories?.filter((fil) => {
        return fil.name === "Internal";
      })[0],
      mergeCategory: beneTransCategories?.categories?.filter((fil) => {
        return fil.name === "Internal";
      })[0]?.configuration?.data,
    });
    handleCloseModal();
  };

  return (
    <>
      {!isMobileView && (
        <div
          className={`beneContact_Main gap-10 ${
            props?.direction === "next" && !props.isModalOpen
              ? "container-righttoleft-animate-intrtxn"
              : props?.direction === "previous" && !props.isModalOpen
              ? "container-lefttoright-animate-intrtxn"
              : ""
          }`}
        >
          {props.isModalOpen && (
            <BeneTxnAccountModal
              onClose={handleCloseModal}
              onAccountSelect={handleAccountSelection}
              accountNumber={accountNumber}
              IFSC={IFSC}
              bankLogo={bankLogo}
              setTxnPayLoad={props.setTxnPayLoad}
              txnPayLoad={props.txnPayLoad}
              accountModalSearch={props.accountModalSearch}
              setAccountModalSearch={props.setAccountModalSearch}
              filterPayload={props.filterPayload}
              setFilterPayload={props.setFilterPayload}
              flow={"internalTxn"}
              setOpenInternalAssociate={setOpenInternalAssociate}
              setIsNeft={props?.setIsNeft}
              setIsImps={props?.setIsImps}
              setIsRtgs={props?.setIsRtgs}
            />
          )}

          {openInternalAssociate && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
              <BeneTxnAccountModal
                // onClose={closeAssociatedAccountsFound}
                // onAccountSelect={handleAccountSelection}
                // accountNumber={props?.txnPayLoad?.bankAccountNo}
                // IFSC={props?.txnPayLoad?.bankAccountIFSC}
                // bankLogo={bankLogo}
                // setTxnPayLoad={props.setTxnPayLoad}
                // txnPayLoad={props.txnPayLoad}
                // accountModalSearch={props.accountModalSearch}
                // setAccountModalSearch={props.setAccountModalSearch}
                // filterPayload={props.filterPayload}
                // setFilterPayload={props.setFilterPayload}
                // flow={"internalTxn"}
                // associateTxn={"associateTxn"}
                // setOpenInternalAssociate={setOpenInternalAssociate}
                // setIsNeft={props?.setIsNeft}
                // setIsImps={props?.setIsImps}
                // setIsRtgs={props?.setIsRtgs}
                // // associateAccount={true}
                // panNumber={props?.txnPayLoad?.pan}
                // beneAccountId={props?.txnPayLoad?.beneAccountId}
                //sdvgsdgsfdgfsgdfsd

                onClose={closeAssociatedAccountsFound}
                onAccountSelect={handleAccountSelection}
                accountNumber={props?.txnPayLoad?.bankAccountNo}
                IFSC={props?.txnPayLoad?.bankAccountIFSC}
                associateTxn={"associateTxn"}
                flow={"internalTxn"}
                panNumber={props?.txnPayLoad?.pan}
                setOpenInternalAssociate={setOpenInternalAssociate}
                beneAccountId={props?.txnPayLoad?.beneAccountId}
                // setOpenBeneAssociate={setOpenBeneAssociate}
              />
            </div>
          )}

          <div>
            <div className=" flex">
              <InternalTxnSettleMethod
                selectBank={props?.selectBank}
                setSelectBank={props?.setSelectBank}
                filterPayload1={props?.filterPayload1}
                setFilterPayload1={props?.setFilterPayload1}
                filterPayloadNonActive={props?.filterPayloadNonActive}
                setFilterPayloadNonActive={props?.setFilterPayloadNonActive}
                smallSearch={props?.smallSearch}
                setSmallSearch={props?.setSmallSearch}
                selectSortAmt={props?.selectSortAmt}
                setSelectSortAmt={props?.setSelectSortAmt}
                isConnectedSelected={props?.isConnectedSelected}
                setIsConnectedSelected={props?.setIsConnectedSelected}
                BenEnableNextButton={props?.BenEnableNextButton}
                txnPayLoad={props?.txnPayLoad}
                setTxnPayLoad={props?.setTxnPayLoad}
                isNeft={props?.isNeft}
                setIsNeft={props?.setIsNeft}
                isRtgs={props?.isRtgs}
                setIsRtgs={props?.setIsRtgs}
                isImps={props?.isImps}
                setIsImps={props?.setIsImps}
                transactionFilterData={props?.transactionFilterData}
                setTransactionFilterData={props?.setTransactionFilterData}
                refreshId={props?.refreshId}
                setRefreshId={props?.setRefreshId}
                openOwner={props?.openOwner}
                setOpenOwner={props?.setOpenOwner}
                selectBankNonActive={props?.selectBankNonActive}
                setSelectBankNonActive={props?.setSelectBankNonActive}
                smallSearchNonActive={props?.smallSearchNonActive}
                setSmallSearchNonActive={props?.setSmallSearchNonActive}
                activeStep={props?.activeStep}
                direction={props?.direction}
                setDirection={props?.setDirection}
                beneAccountId={beneAccountId}
                isConfidential={props?.isConfidential}
                setIsConfidential={props?.setIsConfidential}
              />

              <div className=" mt-[70px] ml-[10px]">
                <img src={arrow_right_blue} className=" w-[20px] h-[20px]" />
              </div>

              <div
                className={`Inter_dividecontact ${
                  accountNumber && IFSC ? "ml-[10px]" : "ml-[10px]"
                } `}
              >
                <div
                  className={`w-1/2 h-fit contactright ${
                    props?.txnPayLoad?.ownerBankAccountNo ? "" : "opacity-50 "
                  }`}
                  // style={{ border: "2px solid yellow" }}
                >
                  <div className="bg-[#CE720F] flex flex-col relative rounded-[20px]">
                    <div
                      className="bg-[#29487E] flex flex-col relative rounded-[20px]"
                      // style={{ border: "2px solid red" }}
                    >
                      <div className="  pt-[20px] pb-[20px] h-full  flex flex-col rounded-[20px] bg-[#3A5686] ">
                        {props?.txnPayLoad?.bankAccountIFSC &&
                          props?.txnPayLoad?.bankAccountName && (
                            <>
                              <div
                                className={`absolute right-0 top-0 py-[10px] px-[15px] ${
                                  props?.txnPayLoad?.ownerBankAccountNo
                                    ? "cursor-pointer"
                                    : "cursor-default"
                                }  bg-[#667CA3] rounded-tr-[20px] rounded-bl-[20px] w-[46px] h-[36px]`}
                                onClick={handleOpenModal}
                                style={{
                                  pointerEvents:
                                    props?.txnPayLoad?.ownerId === null
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <img
                                  className=" w-[16px] h-[16px]"
                                  src={edit_white}
                                />
                              </div>
                            </>
                          )}

                        <div className="w-full flex flex-col px-5 gap-4">
                          {props?.txnPayLoad?.bankAccountIFSC &&
                          props?.txnPayLoad?.bankAccountName ? (
                            <>
                              <div className=" items-center justify-between">
                                <div className="text-[#DADADA] font-semibold text-base">
                                  {props?.title}
                                </div>
                                <div className=" w-[432px] h-[59px]  my-[16px] bg-[#314D7E] flex rounded-[10px] px-[20px] py-[10px]">
                                  <div className=" w-1/2 border-r h-[39px] border-[#031F4F]">
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={props?.txnPayLoad?.bankAccountName}
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <p className="text-[#F9F9F9] text-base text-left font-sans font-normal capitalize w-[180px] truncate block overflow-ellipsis">
                                        {props?.txnPayLoad?.bankAccountName.toLowerCase()}
                                      </p>
                                      <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                                        Account name
                                      </p>
                                    </Tooltip>
                                  </div>
                                  <div className=" w-1/2  h-[39px] pl-[20px] ">
                                    <p className="w-[164px] font-sans text-[#f9f9f9] text-[14px] font-[600px]">
                                      {formatAccountNumber(
                                        props?.txnPayLoad?.bankAccountNo
                                      )}
                                    </p>
                                    <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                                      Account number
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className=" items-center justify-between">
                                <div className="text-[#DADADA] font-semibold text-base">
                                  {props?.title}
                                </div>
                                <div className=" text-[12px] font-sans font-[400px] text-[#f9f9f9]">
                                  The amount will be credited to this account
                                  once distribution is completed.
                                </div>
                              </div>
                              <Button
                                className="w-full antialiased text-[16px]"
                                size="large"
                                sx={{
                                  backgroundColor: "#2A487E",
                                  color: "#FF7F33",
                                  "&:hover": {
                                    backgroundColor: "#2A487E",
                                  },
                                  textTransform: "none !important",
                                  borderRadius: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  fontFamily: "Open Sans",
                                  fontWeight: "600",
                                  cursor: props?.txnPayLoad?.ownerBankAccountNo
                                    ? "pointer"
                                    : "default",
                                }}
                                onClick={
                                  props?.txnPayLoad?.ownerBankAccountNo &&
                                  handleOpenModal
                                }
                              >
                                {accountName ? (
                                  <>
                                    <Tooltip
                                      title={accountName}
                                      arrow
                                      placement="bottom"
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: "black",
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: "black",
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <span
                                          style={{
                                            flex: "1",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            color: " #FF7F33",
                                            fontSize: "20px",
                                            textAlign: "left",
                                            font: " normal normal 600 16px/22px Open Sans",
                                            letterSpacing: " 0px",
                                            textTransform: "capitalize",
                                            opacity: "1",
                                          }}
                                        >
                                          {accountName?.toLowerCase()}
                                        </span>
                                        <img
                                          src={EditAccount}
                                          alt="edit account"
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                            marginLeft: "10px",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={SelectUser}
                                      alt="select user"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                    />
                                    Select a destination account
                                  </>
                                )}
                              </Button>
                            </>
                          )}
                        </div>
                        {props?.txnPayLoad?.bankAccountIFSC &&
                          props?.txnPayLoad?.bankAccountName && (
                            <>
                              <div
                                className="Inter_settle_main_div_container_img"
                                style={{
                                  display:
                                    props?.txnPayLoad?.ownerBankAccountNo ===
                                    null
                                      ? "none"
                                      : "flex",
                                }}
                              >
                                <img
                                  src={require("../../../assets/IfscLogo/" +
                                    IfscCodeMapper(
                                      props?.txnPayLoad?.bankAccountIFSC
                                    ))}
                                  alt="bank"
                                />
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    <div className="-mx-2">
                      <div className="w-full flex flex-col px-4 gap-6">
                        {props?.txnPayLoad?.bankAccountIFSC &&
                          props?.txnPayLoad?.bankAccountName && (
                            <div className="rounded-l-large flex w-full">
                              <div
                                className="w-full  rounded-[20px] py-[15px] flex items-center px-4 text-white cursor-pointer"
                                style={{
                                  pointerEvents:
                                    props?.txnPayLoad?.ownerId === null
                                      ? "none"
                                      : "auto",
                                }}
                                onClick={openAssociatedAccountsFound}
                              >
                                <div className="flex items-center w-full">
                                  <img
                                    src={info_filled}
                                    className=" w-[20px] h-[20px]"
                                  />
                                  <div className="my-1 text-[14px] ml-[10px]">
                                    Active associated accounts found
                                  </div>
                                </div>
                                <div className="ml-auto cursor-pointer">
                                  <img
                                    className="w-6"
                                    src={arrow_right_orange}
                                    alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Inter_settleType_main_div">
                  <p>Select Payment Method</p>
                  <div className="Inter_settleType_neft_imps_rfgs_container">
                    <div
                      className="Inter_settleType_neft_imps_rfgs_container_back"
                      style={{
                        left: props?.isRtgs ? "235px" : "0px",
                        borderRadius: props?.isRtgs
                          ? "0px 20px 20px 0px"
                          : "20px 0px 0px 20px",
                      }}
                    ></div>
                    <Button
                      className="Inter_settleType_neft_imps_rfgs_container_inner"
                      onClick={() => {
                        if (!props?.isNeft) {
                          props?.setSlideAnimation(true);
                        }
                        setTimeout(() => {
                          props?.setSlideAnimation(false);
                        }, [100]);
                        props?.setIsNeft(true);
                        props?.setIsImps(false);
                        props?.setIsRtgs(false);
                        props?.setTxnPayLoad({
                          ...props?.txnPayLoad,
                          settleType: "neft",
                        });
                        props?.BenEnableNextButton(false);
                      }}
                    >
                      <h6>NEFT</h6>
                      <p
                        style={{ color: props?.isNeft ? "#FFFFFF" : "#DADADA" }}
                      >
                        Upto 30 mins transfer during banking hours
                      </p>
                    </Button>
                    <Button
                      className="Inter_settleType_neft_imps_rfgs_container_inner settleType_rfgs_inner"
                      onClick={() => {
                        if (!props?.isRtgs) {
                          props?.setSlideAnimation(true);
                        }
                        setTimeout(() => {
                          props?.setSlideAnimation(false);
                        }, [100]);
                        props?.setIsNeft(false);
                        props?.setIsImps(false);
                        props?.setIsRtgs(true);
                        props?.setTxnPayLoad({
                          ...props?.txnPayLoad,
                          settleType: "rtgs",
                        });
                      }}
                    >
                      <h6>RTGS</h6>
                      <p
                        style={{ color: props?.isRtgs ? "#FFFFFF" : "#DADADA" }}
                      >
                        Instant transfer during banking hours
                      </p>
                    </Button>
                  </div>
                  <div className="settleType_neft_imps_rfgs_container_info">
                    {props?.isImps ? (
                      <div
                        className="neft_container_info"
                        style={{
                          animation:
                            props?.isImps && props?.slideAnimation
                              ? "slideFromRight 0.4s ease forwards"
                              : "",
                        }}
                      >
                        <h6>IMPS</h6>
                        <div className="neft_container_info_bullet">
                          <p>
                            • Transactions executed every 30 mins upon approval
                          </p>
                          <p>• Maximum transaction limit ₹ 2 Lakhs</p>
                          <p>• Additional charges are applicable</p>
                        </div>
                      </div>
                    ) : props?.isRtgs ? (
                      <div
                        className="neft_container_info"
                        style={{
                          animation:
                            props?.isRtgs && props?.slideAnimation
                              ? "slideFromRight 0.4s ease forwards"
                              : "",
                        }}
                      >
                        <h6>RTGS</h6>
                        <div className="neft_container_info_bullet">
                          <p>• Transactions executed instantly upon approval</p>
                          <p>• Minimum transaction limit ₹ 2 Lakhs</p>
                          <p>• Additional charges are applicable</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="neft_container_info"
                        style={{
                          animation:
                            props?.isNeft && props?.slideAnimation
                              ? "slideFromRight 0.4s ease forwards"
                              : "",
                        }}
                      >
                        <h6>NEFT</h6>
                        <div className="neft_container_info_bullet">
                          <p>
                            • Transactions executed every 30 mins upon approval
                          </p>
                          <p>• Additional charges are applicable</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="dividecontact invisible">
          <div
            className="w-1/2 contactright"
            // style={{ border: "2px solid green" }}
          >
            <div
              className="bg-[#506994] flex flex-col gap-5 pt-[20px] pl-[30px] relative rounded-[32px] h-[175px]"
              // style={{ border: "2px solid red" }}
            >
              <h4 className="w-[241px] text-left font-semibold text-[16px] leading-[22px] font-open-sans tracking-normal text-[#F9F9F9] opacity-100">
                Set Up Auto Transfer (Optional)
              </h4>
              <p className="w-[387px] h-[34px] text-left font-normal text-[12px] leading-[17px] font-open-sans tracking-normal text-[#DADADA] opacity-100">
                Auto transfer allows you to schedule the transaction for a
                single time or set it up to repeat at your chosen frequency.
              </p>
              <button
                className="w-[412px] flex  justify-around items-center h-[39px] bg-[#2A487E] rounded-[10px] opacity-100"
                onClick={() => {
                  toast.info("Feature coming soon", toast_position);
                }}
              >
                <p className="text-left font-normal text-[14px] leading-[19px] font-open-sans tracking-normal text-white opacity-100">
                  Click here to set up auto transfer
                </p>
                <div className="w-[18px] h-[18px] flex items-center justify-center bg-[#1D3A6D] rounded-[2px]">
                  <img
                    src={require("../../../assets/Beneficiary Icons/arrowright.png")}
                    alt="Description"
                    className="w-[10px] h-[10px]"
                  />
                </div>
              </button>
            </div>
          </div>
         </div> */}
          </div>
        </div>
      )}
      {isMobileView && (
        <div
          className={`beneContact_Main_res gap-10 ${
            props?.direction === "next" && !props.isModalOpen
              ? "container-righttoleft-animate-intrtxn"
              : props?.direction === "previous" && !props.isModalOpen
              ? "container-lefttoright-animate-intrtxn"
              : ""
          }`}
          style={{ width: `${windowWidth}px` }}
        >
          {props.isModalOpen && (
            <BeneTxnAccountModal
              onClose={handleCloseModal}
              onAccountSelect={handleAccountSelection}
              accountNumber={accountNumber}
              IFSC={IFSC}
              bankLogo={bankLogo}
              setTxnPayLoad={props.setTxnPayLoad}
              txnPayLoad={props.txnPayLoad}
              accountModalSearch={props.accountModalSearch}
              setAccountModalSearch={props.setAccountModalSearch}
              filterPayload={props.filterPayload}
              setFilterPayload={props.setFilterPayload}
              flow={"internalTxn"}
              setOpenInternalAssociate={setOpenInternalAssociate}
              setIsNeft={props?.setIsNeft}
              setIsImps={props?.setIsImps}
              setIsRtgs={props?.setIsRtgs}
            />
            // <OwnerAccountPopUp
            //   setOpenOwner={props.setIsModalOpen}
            //   setIsNeft={props?.setIsNeft}
            //   setIsImps={props?.setIsImps}
            //   setIsRtgs={props?.setIsRtgs}
            //   txnPayLoad={props?.txnPayLoad}
            //   setTxnPayLoad={props?.setTxnPayLoad}
            //   BenEnableNextButton={props?.BenEnableNextButton}
            //   flow={"internalTxn"}
            // />
          )}

          {openInternalAssociate && (
            <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
              {/* <InternalAssociateAccount
                onClose={closeAssociatedAccountsFound}
                onAccountSelect={handleAccountSelection}
                accountNumber={props?.txnPayLoad?.bankAccountNo}
                IFSC={props?.txnPayLoad?.bankAccountIFSC}
                associateTxn={"associateTxn"}
                flow={"internalTxn"}
                panNumber={props?.txnPayLoad?.pan}
                setOpenInternalAssociate={setOpenInternalAssociate}
                beneAccountId={props?.txnPayLoad?.beneAccountId}
              /> */}
              <BeneTxnAccountModal
                onClose={closeAssociatedAccountsFound}
                onAccountSelect={handleAccountSelection}
                accountNumber={props?.txnPayLoad?.bankAccountNo}
                IFSC={props?.txnPayLoad?.bankAccountIFSC}
                associateTxn={"associateTxn"}
                flow={"internalTxn"}
                panNumber={props?.txnPayLoad?.pan}
                setOpenInternalAssociate={setOpenInternalAssociate}
                beneAccountId={props?.txnPayLoad?.beneAccountId}
              />
            </div>
          )}

          <div className="w-[100%]">
            <div className=" flex flex-col">
              <InternalTxnSettleMethod
                selectBank={props?.selectBank}
                setSelectBank={props?.setSelectBank}
                filterPayload1={props?.filterPayload1}
                setFilterPayload1={props?.setFilterPayload1}
                filterPayloadNonActive={props?.filterPayloadNonActive}
                setFilterPayloadNonActive={props?.setFilterPayloadNonActive}
                smallSearch={props?.smallSearch}
                setSmallSearch={props?.setSmallSearch}
                selectSortAmt={props?.selectSortAmt}
                setSelectSortAmt={props?.setSelectSortAmt}
                isConnectedSelected={props?.isConnectedSelected}
                setIsConnectedSelected={props?.setIsConnectedSelected}
                BenEnableNextButton={props?.BenEnableNextButton}
                txnPayLoad={props?.txnPayLoad}
                setTxnPayLoad={props?.setTxnPayLoad}
                isNeft={props?.isNeft}
                setIsNeft={props?.setIsNeft}
                isRtgs={props?.isRtgs}
                setIsRtgs={props?.setIsRtgs}
                isImps={props?.isImps}
                setIsImps={props?.setIsImps}
                transactionFilterData={props?.transactionFilterData}
                setTransactionFilterData={props?.setTransactionFilterData}
                refreshId={props?.refreshId}
                setRefreshId={props?.setRefreshId}
                openOwner={props?.openOwner}
                setOpenOwner={props?.setOpenOwner}
                selectBankNonActive={props?.selectBankNonActive}
                setSelectBankNonActive={props?.setSelectBankNonActive}
                smallSearchNonActive={props?.smallSearchNonActive}
                setSmallSearchNonActive={props?.setSmallSearchNonActive}
                activeStep={props?.activeStep}
                direction={props?.direction}
                setDirection={props?.setDirection}
                beneAccountId={beneAccountId}
                isConfidential={props?.isConfidential}
                setIsConfidential={props?.setIsConfidential}
              />

              <div className="flex justify-center my-6">
                <img
                  src={arrow_right_blue}
                  className="rotate-90 w-[20px] h-[20px]"
                />
              </div>

              <div
                className={`mr-[10px] ${
                  accountNumber && IFSC ? "ml-[10px]" : "ml-[10px]"
                } `}
              >
                <div
                  className={`w-1/2 h-fit contactright ${
                    props?.txnPayLoad?.ownerBankAccountNo ? "" : "opacity-50 "
                  }`}
                  // style={{ border: "2px solid yellow" }}
                >
                  <div className="bg-[#CE720F] flex flex-col relative rounded-[10px]">
                    <div
                      className="bg-[#29487E] flex flex-col relative rounded-[10px]"
                      // style={{ border: "2px solid red" }}
                    >
                      <div className="  pt-4 pb-6 h-full  flex flex-col rounded-[10px] bg-[#3A5686] ">
                        {props?.txnPayLoad?.bankAccountIFSC &&
                          props?.txnPayLoad?.bankAccountName && (
                            <>
                              <div
                                className={`absolute right-0 top-0 py-[10px] px-[15px] ${
                                  props?.txnPayLoad?.ownerBankAccountNo
                                    ? "cursor-pointer"
                                    : "cursor-default"
                                }  bg-[#667CA3] rounded-tr-[10px] rounded-bl-[10px] w-[46px] h-[36px]`}
                                onClick={handleOpenModal}
                                style={{
                                  pointerEvents:
                                    props?.txnPayLoad?.ownerId === null
                                      ? "none"
                                      : "auto",
                                }}
                              >
                                <img
                                  className=" w-[16px] h-[16px]"
                                  src={edit_white}
                                />
                              </div>
                            </>
                          )}

                        <div className="w-full flex flex-col px-5  gap-3">
                          {props?.txnPayLoad?.bankAccountIFSC &&
                          props?.txnPayLoad?.bankAccountName ? (
                            <>
                              <div className=" items-center justify-between">
                                <div className="text-[#DADADA] font-semibold text-base">
                                  {props?.title}
                                </div>
                                <div className="  h-[59px]  my-[16px] bg-[#314D7E] flex rounded-[10px] px-[20px] py-[10px]">
                                  <div className=" w-1/2 border-r-2 h-[39px] border-[#031F4F]">
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={props?.txnPayLoad?.bankAccountName}
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <p className="text-[#F9F9F9] text-base text-left font-sans font-normal capitalize  pr-1 truncate block overflow-ellipsis w-auto">
                                        {props?.txnPayLoad?.bankAccountName.toLowerCase()}
                                      </p>
                                      <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                                        Account name
                                      </p>
                                    </Tooltip>
                                  </div>
                                  <div className=" w-1/2  h-[39px] pl-[20px] ">
                                    <p className="w-[164px] font-sans text-[#f9f9f9] text-[14px] font-[600px]">
                                      {props?.txnPayLoad?.bankAccountNo.substring(
                                        0,
                                        4
                                      ) +
                                        " •••• " +
                                        props?.txnPayLoad?.bankAccountNo.substring(
                                          props?.txnPayLoad?.bankAccountNo
                                            .length - 4
                                        )}
                                    </p>
                                    <p className=" font-sans text-[#dadada] text-[12px] font-normal">
                                      Account number
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className=" items-center justify-between">
                                <div className="text-[#DADADA] font-semibold text-base">
                                  {props?.title}
                                </div>
                                <div className=" text-[12px] font-sans font-[400px] text-[#f9f9f9]">
                                  The amount will be credited to this account
                                  once distribution is completed.
                                </div>
                              </div>
                              <Button
                                className="w-full antialiased text-[16px]"
                                size="large"
                                sx={{
                                  backgroundColor: "#2A487E",
                                  color: "#FF7F33",
                                  "&:hover": {
                                    backgroundColor: "#2A487E",
                                  },
                                  textTransform: "none !important",
                                  borderRadius: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  fontFamily: "Open Sans",
                                  fontWeight: "600",

                                  cursor: props?.txnPayLoad?.ownerBankAccountNo
                                    ? "pointer"
                                    : "default",
                                }}
                                onClick={
                                  props?.txnPayLoad?.ownerBankAccountNo &&
                                  handleOpenModal
                                }
                              >
                                {accountName ? (
                                  <>
                                    <Tooltip
                                      title={accountName}
                                      arrow
                                      placement="bottom"
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: "black",
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: "black",
                                            },
                                          },
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <span
                                          style={{
                                            flex: "1",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            color: " #FF7F33",
                                            fontSize: "20px",
                                            textAlign: "left",
                                            font: " normal normal 600 16px/22px Open Sans",
                                            letterSpacing: " 0px",
                                            textTransform: "capitalize",
                                            opacity: "1",
                                          }}
                                        >
                                          {accountName?.toLowerCase()}
                                        </span>
                                        <img
                                          src={EditAccount}
                                          alt="edit account"
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                            marginLeft: "10px",
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={SelectUser}
                                      alt="select user"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        marginRight: "10px",
                                      }}
                                    />
                                    Select a destination account
                                  </>
                                )}
                              </Button>
                            </>
                          )}
                        </div>
                        {props?.txnPayLoad?.bankAccountIFSC &&
                          props?.txnPayLoad?.bankAccountName && (
                            <>
                              <div
                                className="Inter_settle_main_div_container_img"
                                style={{
                                  display:
                                    props?.txnPayLoad?.ownerBankAccountNo ===
                                    null
                                      ? "none"
                                      : "flex",
                                  borderRadius: "10px 0px",
                                }}
                              >
                                <img
                                  src={require("../../../assets/IfscLogo/" +
                                    IfscCodeMapper(
                                      props?.txnPayLoad?.bankAccountIFSC
                                    ))}
                                  alt="bank"
                                />
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                    <div className="-mx-2">
                      <div className="w-full flex flex-col px-4 gap-6">
                        {accountNumber && IFSC && (
                          <div className="rounded-l-[20px] flex w-full">
                            <div
                              className="w-full  rounded-[20px] py-[15px] flex items-center px-4 text-white cursor-pointer"
                              onClick={openAssociatedAccountsFound}
                              style={{
                                pointerEvents:
                                  props?.txnPayLoad?.ownerId === null
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <div className="flex items-center w-full">
                                <img
                                  src={info_filled}
                                  className=" w-[20px] h-[20px]"
                                />
                                <div className="my-1 text-[14px] ml-[10px]">
                                  Active associated accounts found
                                </div>
                              </div>
                              <div className="ml-auto cursor-pointer">
                                <img
                                  className="w-6"
                                  src={arrow_right_orange}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Inter_settleType_main_div_res">
                  <p>Select Payment Method</p>
                  <div
                    className=" Inter_settleType_neft_imps_rfgs_container"
                    style={{
                      width: "100%",
                      borderRadius: "5px",
                    }}
                  >
                    <div
                      className=" Inter_settleType_neft_imps_rfgs_container_back"
                      style={{
                        width: "50%",
                        borderRadius: "5px",
                        left: props?.isRtgs ? "50%" : "0px",
                      }}
                    ></div>
                    <Button
                      className="Inter_settleType_neft_imps_rfgs_container_inner"
                      style={{
                        width: "50%",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        if (!props?.isNeft) {
                          props?.setSlideAnimation(true);
                        }
                        setTimeout(() => {
                          props?.setSlideAnimation(false);
                        }, [100]);
                        props?.setIsNeft(true);
                        props?.setIsImps(false);
                        props?.setIsRtgs(false);
                        props?.setTxnPayLoad({
                          ...props?.txnPayLoad,
                          settleType: "neft",
                        });
                        props?.BenEnableNextButton(false);
                      }}
                    >
                      <h6>NEFT</h6>
                      <p
                        style={{ color: props?.isNeft ? "#FFFFFF" : "#DADADA" }}
                      >
                        Upto 30 mins transfer during banking hours
                      </p>
                    </Button>
                    <Button
                      className="Inter_settleType_neft_imps_rfgs_container_inner settleType_rfgs_inner"
                      style={{
                        width: "50%",
                      }}
                      onClick={() => {
                        if (!props?.isRtgs) {
                          props?.setSlideAnimation(true);
                        }
                        setTimeout(() => {
                          props?.setSlideAnimation(false);
                        }, [100]);
                        props?.setIsNeft(false);
                        props?.setIsImps(false);
                        props?.setIsRtgs(true);
                        props?.setTxnPayLoad({
                          ...props?.txnPayLoad,
                          settleType: "rtgs",
                        });
                      }}
                    >
                      <h6>RTGS</h6>
                      <p
                        style={{ color: props?.isRtgs ? "#FFFFFF" : "#DADADA" }}
                      >
                        Instant transfer during banking hours
                      </p>
                    </Button>
                  </div>
                  <div className="settleType_neft_imps_rfgs_container_info">
                    {props?.isImps ? (
                      <div
                        className="neft_container_info"
                        style={{
                          animation:
                            props?.isImps && props?.slideAnimation
                              ? "slideFromRight 0.4s ease forwards"
                              : "",
                        }}
                      >
                        <h6>IMPS</h6>
                        <div className="neft_container_info_bullet">
                          <p>
                            • Transactions executed every 30 mins upon approval
                          </p>
                          <p>• Maximum transaction limit ₹ 2 Lakhs</p>
                          <p>• Additional charges are applicable</p>
                        </div>
                      </div>
                    ) : props?.isRtgs ? (
                      <div
                        className="neft_container_info"
                        style={{
                          animation:
                            props?.isRtgs && props?.slideAnimation
                              ? "slideFromRight 0.4s ease forwards"
                              : "",
                        }}
                      >
                        <h6>RTGS</h6>
                        <div className="neft_container_info_bullet">
                          <p>• Transactions executed instantly upon approval</p>
                          <p>• Minimum transaction limit ₹ 2 Lakhs</p>
                          <p>• Additional charges are applicable</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="neft_container_info"
                        style={{
                          animation:
                            props?.isNeft && props?.slideAnimation
                              ? "slideFromRight 0.4s ease forwards"
                              : "",
                        }}
                      >
                        <h6>NEFT</h6>
                        <div className="neft_container_info_bullet">
                          <p>
                            • Transactions executed every 30 mins upon approval
                          </p>
                          <p>• Additional charges are applicable</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="dividecontact invisible">
          <div
            className="w-1/2 contactright"
            // style={{ border: "2px solid green" }}
          >
            <div
              className="bg-[#506994] flex flex-col gap-5 pt-[20px] pl-[30px] relative rounded-[32px] h-[175px]"
              // style={{ border: "2px solid red" }}
            >
              <h4 className="w-[241px] text-left font-semibold text-[16px] leading-[22px] font-open-sans tracking-normal text-[#F9F9F9] opacity-100">
                Set Up Auto Transfer (Optional)
              </h4>
              <p className="w-[387px] h-[34px] text-left font-normal text-[12px] leading-[17px] font-open-sans tracking-normal text-[#DADADA] opacity-100">
                Auto transfer allows you to schedule the transaction for a
                single time or set it up to repeat at your chosen frequency.
              </p>
              <button
                className="w-[412px] flex  justify-around items-center h-[39px] bg-[#2A487E] rounded-[10px] opacity-100"
                onClick={() => {
                  toast.info("Feature coming soon", toast_position);
                }}
              >
                <p className="text-left font-normal text-[14px] leading-[19px] font-open-sans tracking-normal text-white opacity-100">
                  Click here to set up auto transfer
                </p>
                <div className="w-[18px] h-[18px] flex items-center justify-center bg-[#1D3A6D] rounded-[2px]">
                  <img
                    src={require("../../../assets/Beneficiary Icons/arrowright.png")}
                    alt="Description"
                    className="w-[10px] h-[10px]"
                  />
                </div>
              </button>
            </div>
          </div>
        </div> */}
        </div>
      )}
    </>
  );
};

export default InternalTxnSelectAccount;
