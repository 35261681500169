import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Tooltip, tooltipClasses } from "@mui/material";
import ArrowLeft from "../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import arrow_down_red from "../../../assets/arrow_down_white_filled.svg";
import cancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import search_icon from "../../../assets/FilterSearchIcons/search_white.svg";
import enter_ifsc from "../../../assets/Graphics/Enter in search bar, to fetch.svg";
import dataLoading from "../../../assets/LoaderIcons/Loader.gif";
import notFound from "../../../assets/No transaction found.svg";
import { checkUsertoken } from "../../../redux/features/login/login";
import {
  beneVerifyThisBankNames,
  beneVerifyThisIFSC,
} from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import IfscCodeMapper from "../../../components/_utils/IfscLogoMapper";
import PaginationComponent from "../../../components/_utils/PaginationComponent";

const IfscSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showBankFilter, setShowBankFilter] = useState(true);
  const [bankNames, setBankNames] = useState([]);
  const [filterBankName, setFilterBankName] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [selectIFSC, setSelectIFSC] = useState("");
  const [ifscDetails, setIfscDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [ifscLoading, setIfscLoading] = useState("");
  const [noDataFound, setNoDataFound] = useState(false);
  const [prvBank, setPrvBank] = useState("");
  const [prvIfsc, setPrvIfsc] = useState("");
  const [bankLoading, setBankLoading] = useState(true);
  const inputRef = useRef("");
  const bankInputRef = useRef("");
  const filerCloser = useRef("");
  const dropdownOptions = [50, 100];
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(50, 100);
  const [ifscData, setIfscData] = useState([]);
  const { verifyIFSCisLoading, verifyIFSC, isError } = useSelector(
    (state) => state.beneAllVerification
  );
  const [showPagination, setShowPagination] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const {
    beneVerifyThisBankNamesLoading,
    beneVerifyThisBankNamesData,
    beneVerifyThisBankNamesError,
  } = useSelector((state) => state.beneAllVerification);

  const services = useSelector((state) => state.refreshToken.services);

  const handleToggle = () => {
    setShowBankFilter(!showBankFilter);
  };

  const handleSelectedBank = (bankName) => {
    setSelectedBank(bankName);
    setShowBankFilter(!showBankFilter);
    setFilterBankName("");
  };

  const handleSelectIfsc = (e) => {
    const ifscValue = e.target.value.toUpperCase();
    const updateData = ifscValue.replace(/[^A-Z0-9]/g, "");
    if (updateData.length <= 11) {
      setSelectIFSC(updateData);
    }
    if (ifscValue.length == 0) {
      handleIfscClear();
    }
    const cursorPosition = e.target.selectionStart;
    setTimeout(() => {
      e.target.focus();
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };
  const handleIfscSearch = async () => {
    const ifscValidation = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (selectedBank != "" || ifscValidation.test(selectIFSC)) {
      setIfscLoading(true);
      let queryParams = {
        page_no: currentPage,
        page_size: pageSize,
        search_string:
          selectedBank != ""
            ? selectedBank
            : selectIFSC.length == 11
            ? selectIFSC
            : "",
      };
      dispatch(checkUsertoken());
      let response = await dispatch(beneVerifyThisIFSC(queryParams));

      setIfscData(response?.payload);
      if (
        response?.payload?.ifsc_details &&
        response?.payload?.title &&
        response?.payload?.total_count
      ) {
        let newData = response?.payload?.ifsc_details;
        setIfscDetails((prevData) => {
          return currentPage == 1 ||
            prvBank != selectedBank ||
            prvIfsc != selectIFSC
            ? newData
            : [...prevData, ...newData];
        });
        setTotalCount(response?.payload?.total_count);
        setIfscLoading(false);
        setPrvBank(selectedBank);
        setPrvIfsc(selectIFSC);
        setShowPagination(true);
      } else if (response?.error) {
        setShowPagination(false);
        if (response?.error?.message == "Rejected") {
          toast.error("Bank Details Not Found", toast_position);
        } else {
          toast.error(response?.error?.message, toast_position);
        }
        setTimeout(() => {
          setIfscDetails(false);
          setFilterBankName("");
          setIfscLoading(false);
          setNoDataFound(true);
        }, 1000);
      }
    } else {
      setShowPagination(false);
      if (selectedBank == "" && selectIFSC == "") {
        toast.error("Select Bank OR Enter IFSC Code", toast_position);
      } else if (!ifscValidation.test(selectIFSC)) {
        toast.error(
          "IFSC code format is incorrect OR invalid!",
          toast_position
        );
        handleIfscClear();
      }
    }
  };

  const handleIfscClear = () => {
    setIfscDetails(false);
    setFilterBankName("");
    setIfscLoading(false);
    setNoDataFound(false);
    setShowPagination(false);
    setIfscData([]);
  };

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneVerifyThisBankNames());
    if (services && !services?.includes("VIFSC")) {
      navigate("/feature_not_assigned");
    }
  }, []);

  const handleIFSCDetails = (IFSC) => {
    const ifscValidation = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (ifscValidation.test(IFSC)) {
      let id = IFSC;
      navigate(`/settings/IFSC/IFSC_Details/${id}`);
    }
  };

  const handleOpenDropDown = (e) => {
    if (selectIFSC == "") {
      e.stopPropagation();
      setBankLoading(false);
      setFilterBankName("");
      setTimeout(() => {
        setBankLoading(true);
        if (selectIFSC == "") {
          handleToggle();
        }
      }, [500]);
    }
  };

  useEffect(() => {
    setBankNames(
      !beneVerifyThisBankNamesLoading
        ? beneVerifyThisBankNamesData?.bank_names
        : []
    );
  }, [beneVerifyThisBankNamesLoading, beneVerifyThisBankNamesData]);

  useEffect(() => {
    const getNextData = async () => {
      const ifscValidation = /^[A-Z]{4}0[A-Z0-9]{6}$/;
      if (selectedBank !== "" || ifscValidation.test(selectIFSC)) {
        let queryParams = {
          page_no: currentPage,
          page_size: pageSize,
          search_string:
            selectedBank !== ""
              ? selectedBank
              : selectIFSC.length === 11
              ? selectIFSC
              : "",
        };
        dispatch(checkUsertoken());
        let response = await dispatch(beneVerifyThisIFSC(queryParams));
        if (
          response?.payload?.ifsc_details &&
          response?.payload?.title &&
          response?.payload?.total_count
        ) {
          setIfscData(response?.payload);
          let newData = response?.payload?.ifsc_details;
          setIfscDetails(newData);
          setTotalCount(response?.payload?.total_count);
          setShowPagination(true);
        } else if (response?.error) {
          toast.error(response?.error?.message, toast_position);
          setTimeout(() => {
            setIfscDetails(false);
            setFilterBankName("");
            setIfscLoading(false);
            setShowPagination(false);
          }, 1000);
        }
      }
    };
    getNextData();
  }, [pageSize, currentPage]);

  useEffect(() => {
    const handleOutSideClick = (e) => {
      if (filerCloser?.current && !filerCloser?.current.contains(e.target)) {
        setShowBankFilter(true);
      }
    };
    document.addEventListener("click", handleOutSideClick);
  }, [filerCloser]);

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      if (selectIFSC.trim().length === 11 || selectedBank !== "") {
        handleIfscSearch();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, [selectIFSC, selectedBank]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleSelect = (value) => {
    setSelectedValue(value);
    setPageSize(value);
    setCurrentPage(1);
    toggleDropdown();
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="h-[100dvh] relative overflow-hidden">
      {showPagination && (
        <div
          style={{
            position: "absolute",
            zIndex: "999",
            width: "100%",
            bottom: "0",
            padding: "0 20px",
          }}
          className=" bg-custom-appBackLight h-[50px]"
        >
          <PaginationComponent
            handleSelect={handleSelect}
            selectedValue={selectedValue}
            data={ifscData}
            pageSize={pageSize}
            dropdownOptions={dropdownOptions}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
      )}
      <div
        id="beneScrollContainer "
        className={`bg-custom-appBack flex flex-col h-screen`}
        style={{ overflow: "hidden" }}
      >
        <div
          className="lg:hidden fixed w-full top-0 bg-custom-lightBlue h-[56px] sm:h-16 flex items-center cursor-pointer"
          style={{ zIndex: 999 }}
          onClick={() => navigate(-1)}
        >
          <img src={ArrowLeft} alt="Close" className="w-4 h-4 m-5" />
          <h1 className="text-base flex-1 text-Primary_white">
            IFSC Verification
          </h1>
        </div>
        <div className="ifsc-BlueStripMob"></div>
        <div className="bg-custom-card">
          <div className="ifsc-first-div max-w-[1024px] mx-auto">
            <div className="ifsc-bar-container">
              {showBankFilter ? (
                <>
                  <div
                    className={`ifsc-bank-dropdown hand-pointer ${
                      selectIFSC != "" ? "block-pointer" : ""
                    }`}
                    onClick={(e) => {
                      handleOpenDropDown(e);
                    }}
                  >
                    <div
                      className={`ifsc-partition ifsc-partition-css ${
                        selectIFSC != "" ? "block-pointer" : ""
                      }`}
                    >
                      <Tooltip
                        placement="top-end"
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={selectedBank}
                      >
                        <div
                          className={`ifsc-partition-one ifsc-partition-one-css ${
                            selectIFSC != "" ? "block-pointer" : ""
                          }`}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {selectedBank == ""
                            ? "Select bank to fetch IFSC"
                            : selectedBank}
                        </div>
                      </Tooltip>
                      <div
                        className={`ifsc-partition-two flex justify-end ${
                          selectIFSC != "" ? "block-pointer" : ""
                        }`}
                      >
                        {selectedBank.length === 0 ? (
                          <></>
                        ) : (
                          <>
                            <div
                              className="md:pr-[5px] pr-[10px]"
                              style={{
                                borderRight: "1px solid black",
                              }}
                            >
                              <img
                                className="ifsc-cancle-img"
                                alt="cancel"
                                src={cancel}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedBank("");
                                  setIfscLoading(false);
                                  setIfscDetails(false);
                                  setNoDataFound(false);
                                  setIfscData([]);
                                  setShowPagination(false);
                                }}
                              />
                            </div>
                          </>
                        )}
                        <div
                          className={`${
                            selectedBank.length === 0
                              ? "pl-[0px]"
                              : "md:pl-[5px] pl-[10px]"
                          }`}
                        >
                          <img
                            className="ifsc-down-img"
                            src={bankLoading ? arrow_down_red : dataLoading}
                            alt=""
                            onClick={(e) => {
                              handleOpenDropDown(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="ifsc-bank-dropdown-expand"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className="ifsc-partition ifsc-partition-css hand-pointer"
                      onClick={(e) => {
                        handleToggle();
                        e.stopPropagation();
                        setFilterBankName("");
                      }}
                      ref={filerCloser}
                    >
                      <div
                        className="ifsc-partition-one ifsc-partition-one-css"
                        style={{
                          whiteSpace: " nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedBank == ""
                          ? "Select bank to fetch IFSC"
                          : selectedBank}
                      </div>
                      <div className="ifsc-partition-two hand-pointer flex justify-end">
                        {selectedBank.length === 0 ? (
                          <>
                            <img
                              className="ifsc-down-img ifsc-up-img"
                              src={arrow_down_red}
                              alt=""
                              onClick={() => {
                                handleToggle();
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              className="ifsc-cancle-img"
                              alt="cancel"
                              src={cancel}
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedBank("");
                                setIfscData([]);
                                setShowPagination(false);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="splitline-dd mx-[10px]"></div>
                    <div className="ifsc-partition bank-name-search-dd">
                      <div className="ifsc-dropDown-input label-left">
                        <input
                          ref={bankInputRef}
                          className="input-custom"
                          type="text"
                          value={filterBankName}
                          placeholder="Search bank"
                          onChange={(e) => setFilterBankName(e.target.value)}
                          autoFocus
                        />
                      </div>
                      <div
                        className="ifsc-dropdown-search"
                        onClick={() => bankInputRef.current.focus()}
                      >
                        {filterBankName.length === 0 ? (
                          <>
                            <img
                              className="ifsc-down-search"
                              src={search_icon}
                              alt=""
                            />
                          </>
                        ) : (
                          <>
                            <img
                              className="ifsc-cancle-img cursor-pointer"
                              alt="cancel"
                              src={cancel}
                              onClick={(e) => {
                                e.stopPropagation();
                                setIfscDetails(false);
                                setFilterBankName("");
                                setIfscLoading(false);
                                setNoDataFound(false);
                                setShowPagination(false);
                                setIfscData([]);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className="bank-name-dd-options">
                      {bankNames == [] ? (
                        <></>
                      ) : (
                        <>
                          {bankNames.filter((fil) => {
                            return JSON.stringify(fil.toLowerCase()).includes(
                              filterBankName.toLowerCase()
                            );
                          }).length > 0 ? (
                            bankNames
                              .filter((fil) => {
                                return JSON.stringify(
                                  fil.toLowerCase()
                                ).includes(filterBankName.toLowerCase());
                              })
                              .map((res) => {
                                return (
                                  <>
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={res}
                                    >
                                      <div
                                        className="bank-name-search-dd bank-name-search-dd-color bank-name-search-dd-text hand-pointer"
                                        onClick={() => handleSelectedBank(res)}
                                      >
                                        {res}
                                      </div>
                                    </Tooltip>
                                  </>
                                );
                              })
                          ) : (
                            <>
                              <p className=" text-Primary_white p-4 text-sm ">
                                No bank found
                              </p>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="ifsc-bar-container-others">
              <div className="ifsc-option">OR</div>
              <div
                className={`ifsc-code ${
                  selectedBank != "" ? "block-pointer" : ""
                }`}
              >
                <div
                  style={{ padding: "10px 20px" }}
                  className={`ifsc-partition ifsc-partition-css ${
                    selectedBank != "" ? "block-pointer" : ""
                  }`}
                >
                  <div className={`ifsc-partition-input ifsc-input-inner`}>
                    <input
                      className={`input-custom-ifsc ${
                        selectedBank != "" ? "block-pointer" : ""
                      }`}
                      type="text"
                      value={selectIFSC}
                      placeholder="Enter 11 digit IFSC code"
                      onChange={(e) => handleSelectIfsc(e)}
                      disabled={selectedBank != "" ? true : false}
                      ref={inputRef}
                    />
                  </div>
                  <div
                    className={`ifsc-partition-search ${
                      selectedBank != "" ? "block-pointer" : ""
                    }`}
                    onClick={() => inputRef.current.focus()}
                  >
                    {selectIFSC.length === 0 ? (
                      <>
                        <img
                          className="ifsc-down-search"
                          src={search_icon}
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <img
                          className="ifsc-cancle-img cursor-pointer"
                          alt="cancel"
                          src={cancel}
                          onClick={() => {
                            setSelectIFSC("");
                            setIfscDetails(false);
                            setNoDataFound(false);
                            setIfscDetails(false);
                            setFilterBankName("");
                            setIfscLoading(false);
                            setShowPagination(false);
                            setIfscData([]);
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <Button
                sx={{ minWidth: "128px", borderRadius: "10px" }}
                className={`${
                  selectedBank === "" && selectIFSC.length != 11
                    ? "ifsc-fetch-css-dis"
                    : "ifsc-fetch-css"
                }`}
                disabled={
                  selectedBank === "" && selectIFSC.length != 11 ? true : false
                }
                onClick={() => handleIfscSearch()}
              >
                {"Fetch"}
              </Button>
            </div>
          </div>
        </div>
        {ifscLoading == "" && !ifscDetails ? (
          <>
            <div className="ifsc-enter-div flex flex-col items-center text-center">
              <img
                src={noDataFound ? notFound : enter_ifsc}
                alt="Enter Bank or Enter 11 digit IFSC"
                className="w-full h-60 mt-8 lg:mt-16 md:mt-16 sm:mt-8 sm:w-[25rem] sm:h-[18rem] p-5 lg:w-96"
              />
              {noDataFound ? (
                <p className="ifsc-enter-label text-sm md:text-base small-screen-text">
                  No data found. <br />
                  Please check the&nbsp;
                  {selectedBank !== "" ? "bank details" : "IFSC code"}
                  &nbsp;entered above.
                </p>
              ) : (
                <p className="ifsc-enter-label text-sm md:text-base small-screen-text">
                  Select bank or enter 11-digit IFSC <br />
                  code in the search bar to fetch the IFSC details.
                </p>
              )}
            </div>
          </>
        ) : ifscLoading ? (
          <>
            <div className="flex justify-center items-center h-screen">
              <img src={dataLoading} alt="" className="w-10 h-10" />
            </div>
          </>
        ) : ifscDetails != false && !ifscLoading ? (
          <div
            id="infinityScrollContainerIfscView"
            className={"opacity-100 mt-2.5"}
          >
            <div
              className={`scroll flex flex-col items-center justify-center w-full`}
            >
              <div
                style={{
                  opacity: "1",
                }}
                className="ifscCard"
              >
                {ifscDetails.map((ifscDtls) => {
                  return (
                    <div
                      className="ifsc-details-card"
                      onClick={() => handleIFSCDetails(ifscDtls.ifsc)}
                    >
                      <div className="ifsc-code-card">{ifscDtls.ifsc}</div>
                      <div className="ifsc-code-card-label">IFSC code</div>
                      <div
                        className="ifsc-code-card ext-padding"
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          textTransform: "capitalize",
                          width: "9.25rem",
                        }}
                      >
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          title={ifscDtls.branch.toLowerCase()}
                          arrow
                          placement="bottom"
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                        >
                          {ifscDtls.branch.toLowerCase()}
                        </Tooltip>
                      </div>
                      <div className="ifsc-code-card-label">
                        Branch location
                      </div>
                      <div className="ifsc-logo-card-container">
                        <img
                          src={require("../../../assets/IfscLogo/" +
                            IfscCodeMapper(ifscDtls.ifsc))}
                          alt="bank"
                          className="ifsc-bank-image"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default IfscSettings;
