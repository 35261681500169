import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { env } from "../../../../../env";
import { toast } from "react-toastify";
import { toast_position } from "../../Authorization";
import axios from "axios";

const baseUrl = env.REACT_APP_BASE_URL;
// Action
export const getUsers = createAsyncThunk("getUsers", async (values) => {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      // "X-Api-Key": sessionStorage.getItem("q2p_token"),
      "X-Api-Key": sessionStorage.getItem("q2p_token"),
    },
  };

  return await fetch(
    `${baseUrl}/users${values?.user_id ? "/" + values?.user_id : ""}?page_no=${
      values?.page_no || 1
    }&page_size=${values?.page_size || 15}&search_string=${
      values?.search_string || ""
    }`,
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => toast.error(error.response.data.error, toast_position));
});

export const getSpecificUser = createAsyncThunk(
  "getSpecificUser",
  async (id) => {
    try {
      const response = await fetch(`${baseUrl}/users/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });

      return response.json();
    } catch (error) {
      toast.error(error.response.data.error, toast_position);
      console.error("Error", error);
      throw error;
    }
  }
);

export const AddUserTag = createAsyncThunk(
  "AddUserTag",
  async ({ user_id, tags }, { rejectWithValue }) => {
    const data = {
      user_id: user_id,
      account_tags: tags,
    };

    console.log("user tags", data);
    try {
      const response = await axios.put(`${baseUrl}/users/add_tag`, data, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });

      return response.data;
    } catch (error) {
      if (error.response.data.errors?.base) {
        toast.error(error.response.data.errors?.base[0], toast_position);
      } else {
        toast.error(
          error.response.data.errors?.user_unauthorized[0],
          toast_position
        );
      }

      return rejectWithValue(error);
    }
  }
);

export const RemoveUserTag = createAsyncThunk(
  "RemoveUserTag",
  async ({ user_id, tags }, { rejectWithValue }) => {
    const data = {
      user_id: user_id,
      account_tags: tags,
    };

    try {
      const response = await axios.put(`${baseUrl}/users/remove_tag`, data, {
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": sessionStorage.getItem("q2p_token"),
        },
      });

      return response.data;
    } catch (error) {
      if (error.response.data.errors?.base) {
        toast.error(error.response.data.errors?.base[0], toast_position);
      } else {
        toast.error(
          error.response.data.errors?.user_unauthorized[0],
          toast_position
        );
      }

      return rejectWithValue(error);
    }
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    users: null,
    isError: false,
    userStoredPage: 1,
    userStoredPagesize: 50,
    addUserTagsLoading: true,
    addUserTagsData: [],
    addUserTagsError: false,

    removeUserTagsLoading: false,
    removeUserTagsData: [],
    removeUserTagsError: null,

    specificUserData: null,
    specificUserLoading: null,
    specificUserError: null,
  },
  reducers: {
    resetUserpage: (state) => {
      state.userStoredPage = 1;
    },
    setUserStoredPage: (state, action) => {
      state.userStoredPage = action.payload;
    },
    resetUserpagesize: (state) => {
      state.userStoredPagesize = 50;
    },
    setUserStoredPagesize: (state, action) => {
      state.userStoredPagesize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(AddUserTag.pending, (state) => {
      state.addUserTagsLoading = true;
      state.addUserTagsError = null;
    });
    builder.addCase(AddUserTag.fulfilled, (state, action) => {
      state.addUserTagsLoading = false;
      state.addUserTagsData = action.payload;
      state.addUserTagsError = null;
    });
    builder.addCase(AddUserTag.rejected, (state, action) => {
      state.addUserTagsLoading = false;
      state.addUserTagsData = null;
      state.addUserTagsError = action.payload;
    });

    builder.addCase(RemoveUserTag.pending, (state) => {
      state.removeUserTagsLoading = true;
      state.removeUserTagsError = null;
    });
    builder.addCase(RemoveUserTag.fulfilled, (state, action) => {
      state.removeUserTagsLoading = false;
      state.removeUserTagsData = action.payload;
      state.removeUserTagsError = null;
    });
    builder.addCase(RemoveUserTag.rejected, (state, action) => {
      state.removeUserTagsLoading = false;
      state.removeUserTagsData = null;
      state.removeUserTagsError = action.payload;
    });

    builder.addCase(getUsers.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(getSpecificUser.pending, (state, action) => {
      state.specificUserLoading = true;
    });
    builder.addCase(getSpecificUser.fulfilled, (state, action) => {
      state.specificUserLoading = false;
      state.specificUserData = action.payload;
    });
    builder.addCase(getSpecificUser.rejected, (state, action) => {
      state.specificUserError = true;
      state.specificUserData = null;
    });
  },
});

export const {
  setUserStoredPage,
  resetUserpage,
  setUserStoredPagesize,
  resetUserpagesize,
} = usersSlice.actions;
export default usersSlice.reducer;
