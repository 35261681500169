import React, { useEffect } from "react";
import "./index.css";
import CodeAuth from "../../../assets/GeneralIcons/code_auth.svg";
import { useDispatch } from "react-redux";
import greenCheck from "../../../assets/CheckmarksIcons/verified_with_white_circle_green.svg";
import {
  enableTotp,
  disabledEnabledError,
} from "../../../redux/features/totp/totp";
import Loader from "../../../assets/LoaderIcons/Loader.gif";
import ErrorIcon from "../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";

const TotpVerify = ({
  isFocus,
  setIsFocus,
  totp,
  setTotp,
  userId,
  setBackupCodes,
  enableTotpLoading,
  enableTotpData,
  enableTotpError,
}) => {
  const dispatch = useDispatch();

  const handleVerify = (e) => {
    e.preventDefault();
    dispatch(enableTotp({ id: userId, totp: totp }));
  };
  const handleBlur = () => {
    if (totp?.length === 0) {
      setIsFocus(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && totp?.length === 6) {
      handleVerify(e);
    }
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleTotpChange = (e) => {
    let value = e.target.value;
    if (/^\d*$/.test(value) && value?.length <= 6) {
      if (enableTotpError === true) {
        dispatch(disabledEnabledError());
      }
      setTotp(value);
    }
  };

  useEffect(() => {
    if (enableTotpData?.status === true && !enableTotpLoading) {
      setBackupCodes(enableTotpData?.data?.backup_codes);
    }
  }, [enableTotpData, enableTotpLoading]);
  return (
    <div className="totp_Top_Main">
      <div className="divide_totp">
        <div
          className="totp_verify_left"
          style={{
            backgroundColor:
              enableTotpError === true ? "#CE362A" : "transparent",
          }}
        >
          <div className="totp_verify_main_div">
            <h4>Authentication Code</h4>
            <p>
              Enter the 6-digit code generated by the authenticator app and
              verify
            </p>
            <div className="totp_verify_input_div">
              <div
                className="input_Field_totp"
                style={{
                  borderColor:
                    (enableTotpData?.status === true && !enableTotpLoading) ||
                    (totp?.length === 6 && enableTotpError !== true)
                      ? "#00FF14"
                      : enableTotpError === true && !enableTotpLoading
                      ? "#FF5860"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                <label
                  htmlFor="totpid"
                  style={{
                    top: isFocus ? "-12px" : "8px",
                    fontSize: isFocus ? "12px" : "16px",
                    color:
                      (enableTotpData?.status === true && !enableTotpLoading) ||
                      (totp?.length === 6 && enableTotpError !== true)
                        ? "#00FF14"
                        : enableTotpError === true && !enableTotpLoading
                        ? "#FF5860"
                        : isFocus
                        ? "#00FFF5"
                        : "#dadada",
                  }}
                >
                  6 digit code*
                </label>
                {enableTotpData?.status === true && !enableTotpLoading ? (
                  <img src={greenCheck} alt="check" />
                ) : null}
                <input
                  disabled={enableTotpData?.status === true}
                  value={totp}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleTotpChange(e);
                  }}
                  onKeyDown={handleKeyDown}
                  type="text"
                  className="input_Box"
                  spellCheck={false}
                  id="totpid"
                  autoComplete="off"
                />
              </div>
              <button
                className="totp_verify_butt"
                onClick={(e) => handleVerify(e)}
                style={{
                  backgroundColor:
                    enableTotpError === true
                      ? "#707070"
                      : totp?.length === 6
                      ? "#69B76F"
                      : "#707070",
                  pointerEvents:
                    enableTotpError === true || enableTotpData?.status === true
                      ? "none"
                      : totp?.length === 6
                      ? "auto"
                      : "none",
                  opacity: enableTotpData?.status === true ? "0.4" : 1,
                }}
              >
                {enableTotpLoading === true ? (
                  <img src={Loader} alt="Loader" />
                ) : (
                  "Verify Code"
                )}
              </button>
            </div>
          </div>
          <div
            className="totp_verify_left_error_msg"
            style={{ display: enableTotpError === true ? "flex" : "none" }}
          >
            <img
              className="totp_verify_left_error_msg_left"
              src={ErrorIcon}
              alt="ErrorIcon"
            ></img>
            <div className="totp_verify_left_error_msg_right">
              <h4>VERIFICATION FAILED</h4>
              <ul>
                <li className="totp_list">Invalid code</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="divide_totp">
        <div className="totp_verify_right">
          <img src={CodeAuth} alt="CodeAuth" />
        </div>
      </div>
    </div>
  );
};

export default TotpVerify;
