import React, { useEffect, useRef, useState } from "react";
import clear_filter from "../../../assets/ViewAllTransactions/Clear filter.png";
import arrowup from "../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../assets/OwnerAccount/arrowdown1.png";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import allChecker from "../../../assets/Beneficiary Icons/whitecheckmark.png";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";

const BeneTxnStatusPopover = ({
  typepop,
  getAccStatus,
  selectedStatus,
  setPage,
  isDistributionSelected,
  isStatusSelected,
  setIsStatusSelected,
  isPopoverOpen,
  setIsPopoverOpen,
  setCurrentCategory,
  setPaymentMethod,
  setTempEndDate,
  setTempStartDate,
  setSelectedOwnerAccId,
  setSelectedBeneAccId,
  setTxnAmountFilter,
  sendPaymentMethod,
  setEndDateFilter,
  setStartDateFilter,
  setInitCategory,
  setTxnPayLoad,
  txnPayLoad,
  setEndDate,
  setStartDate,
  setBeneAccountId,
  setAccountName,
  DashBoardStatusData,
  SetDashBoardStatusData,
  DistributionStatusData,
  isSidebarOpen,
  showSearchBar,
  txnHistorytype,
}) => {
  const popoverRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  const handleClick = () => {
    if (isDistributionSelected) {
      toast.error(
        "Only one status filter can be selected at a time.",
        toast_position
      );
    } else {
      setIsPopoverOpen((prev) => !prev);
    }
  };

  const handleClose = () => {
    setIsPopoverOpen(false);
  };

  const handleClearStatus = (event) => {
    if (event) event.stopPropagation();
    handleClose();
    getAccStatus(null, "status");
    getAccStatus(null, "approval");
    SetDashBoardStatusData("all");
    setIsStatusSelected(false);

    // if (typepop !== "transactionHistorypopup") {
    //   setAccountName("");
    //   setBeneAccountId("");
    //   setSelectedBeneAccId("");
    // }
    // setAccountName("");
    // setCurrentCategory("");
    // setPaymentMethod("");
    // setTempEndDate("");
    // setTempStartDate("");
    // setSelectedOwnerAccId("");
    // setTxnAmountFilter("");
    // setStartDate(null);
    // setEndDate(null);
    // setSelectedBeneAccId("");
    // sendPaymentMethod("");
    // setStartDateFilter("");
    // setEndDateFilter("");
    // setBeneAccountId("");
    // setInitCategory("");
    setPage(1);
    // setTxnPayLoad({
    //   txnPayLoad,
    //   amount: "",
    //   ownerAccountName: "",
    //   ownerAccountId: "",
    //   ownerbankAccountIFSC: "",
    //   ownerbankAccountNo: "",
    // });
  };

  const handleStatus = (status) => {
    handleClose();

    getAccStatus(status, "status");
    setPage(1);
  };

  const getIcon = () => {
    let iconSrc = null;
    let iconClickHandler = null;

    if (isPopoverOpen) {
      iconSrc = arrowdown;
      iconClickHandler = handleClick;
    } else {
      if (
        !isStatusSelected ||
        selectedStatus === null ||
        selectedStatus === "All"
      ) {
        iconSrc = arrowdown;
        iconClickHandler = handleClick;
      } else {
        iconSrc = clear_filter;
        iconClickHandler =
          selectedStatus !== "All" ? handleClearStatus : undefined;
      }
    }

    return { src: iconSrc, onClick: iconClickHandler };
  };

  const { src: icon, onClick: iconClickHandler } = getIcon();

  const getStatusColor = (status) => {
    switch (status) {
      case "require approval":
      case "pending":
      case "awaiting approval":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "denied":
      case "blocked":
      case "rejected":
      case "cancelled":
        return "#ff5860";
      case "abandoned":
      case "archived":
      case "archived":
        return "#b4b4b4";

      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      case "all":
        return "#f9f9f9";
      case "settled":
        return "#4ddd37";
      default:
        return "#f9f9f9";
    }
  };

  const formatStatusText = (status) => {
    return `${status?.[0]?.toUpperCase()}${status?.slice(1)?.toLowerCase()}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const services = useSelector((state) => state.refreshToken.services);

  const statuses =
    txnHistorytype === "internalTxnHistory" ||
    txnHistorytype === "ownerDetailTxnHistory"
      ? ["all", "approved", "pending", "rejected", "abandoned"]
      : [
          "all",
          "approved",
          "require approval",
          "pending",
          // "denied",
          "rejected",
          // "cancelled",
          // "blocked",
          "abandoned",
          // "archived",
        ];

  const filteredStatuses = statuses.filter((status) => {
    const hasAdmin = services?.includes("Admin");
    const hasABeneficiary = services?.includes("ABeneficiaryTxn");
    const hasAInternal = services?.includes("AInternalTxn");
    const hasCBeneficiary = services?.includes("CBeneficiaryTxn");
    const hasCInternal = services?.includes("CInternalTxn");

    const beneficiaryTxn =
      hasCBeneficiary &&
      !hasABeneficiary &&
      !hasAdmin &&
      status === "require approval";
    const internalTxn =
      hasCInternal &&
      !hasAdmin &&
      !hasAInternal &&
      status === "require approval";

    if (beneficiaryTxn || internalTxn) {
      return false;
    }

    if (
      hasAdmin &&
      hasABeneficiary &&
      hasCBeneficiary &&
      hasAInternal &&
      hasCInternal
    ) {
      return true;
    }

    // if (
    //   hasABeneficiary &&
    //   !hasCBeneficiary &&
    //   !hasAdmin &&
    //   status === "awaiting approval"
    // ) {
    //   return false;
    // }

    // if (
    //   hasAdmin &&
    //   !hasABeneficiary &&
    //   !hasCBeneficiary &&
    //   (status === "require approval" || status === "awaiting approval")
    // ) {
    //   return false;
    // }

    return true;
  });
  useEffect(() => {
    if (
      DashBoardStatusData !== "all" &&
      DashBoardStatusData !== "confidential" &&
      DistributionStatusData === false
    ) {
      handleStatus(DashBoardStatusData);
    }
  }, [DashBoardStatusData]);

  return (
    <div
      ref={popoverRef}
      className="beneTxnViewAllPopover"
      style={{
        opacity: isDistributionSelected ? 0.5 : 1,
        cursor: isDistributionSelected ? "default" : "pointer",
      }}
    >
      <div
        className="benTxnViewall_inner_div flex justify-between relative"
        style={{
          borderBottom: isPopoverOpen ? "1px solid #1d3a6d" : "none",
          opacity: isDistributionSelected ? 0.5 : 1,
          cursor: isDistributionSelected ? "default" : "pointer",
          marginLeft: "0",
          padding: "0 17px",
          //gap: isMobile ? "100px" : "30px",
        }}
        onClick={() => {
          if (!isSidebarOpen && !showSearchBar) {
            if (!isSidebarOpen && !showSearchBar) {
              handleClick();
            }
          }
        }}
      >
        <div className="flex flex-col">
          <button
            id="bene_txn_popover_section"
            style={{ color: getStatusColor(selectedStatus) }}
          >
            {selectedStatus === "denied"
              ? "Denied"
              : selectedStatus === "approved"
              ? "Approved"
              : selectedStatus === "rejected"
              ? "Rejected"
              : formatStatusText(selectedStatus || "All")}
            {/* <img src={icon} alt="Status Icon" className="status_icon" /> */}
          </button>
          <p
            style={{
              textAlign:
                txnHistorytype === "ownerDetailTxnHistory" ? "left" : "center",
              marginLeft:
                txnHistorytype === "ownerDetailTxnHistory" ? "4px" : "-10px",
            }}
            className="beneTxnViewAllchild2"
          >
            Approval status
          </p>
        </div>
        <div
          className="p-[4px]"
          style={{
            width: "20px",
            height: "20px",
            backgroundColor: "#1d3a6d",
            marginTop: "auto",
            marginBottom: "17px",
            borderRadius: "3px",
          }}
          onClick={(event) => {
            if (!isSidebarOpen && !showSearchBar) {
              event.stopPropagation();
              if (iconClickHandler) {
                iconClickHandler();
              }
            }
          }}
        >
          {icon && (
            <img
              src={icon}
              alt={
                selectedStatus === "All" ? "Arrow Icon" : "Clear Filter Icon"
              }
              className={`bene_txn_status_icon transition-all duration-300 ${
                selectedStatus === "All" ? "arrow-icon" : "cancel-icon"
              } ${isPopoverOpen ? "rotate-180" : "rotate-0"}`}
            />
          )}
        </div>
      </div>
      <div
        className={`bene-txn-viewall-popover ${
          isPopoverOpen ? "open" : "closed"
        }`}
        style={{
          width: txnHistorytype === "ownerDetailTxnHistory" && "100%",
          height: isPopoverOpen
            ? txnHistorytype === "internalTxnHistory" ||
              txnHistorytype === "ownerDetailTxnHistory"
              ? "195px"
              : services?.includes("ABeneficiaryTxn") ||
                services?.includes("AInternalTxn") ||
                services?.includes("Admin")
              ? "230px"
              : "195px"
            : "0px",
        }}
      >
        {filteredStatuses.map((status) => (
          <div
            className={`popover h-[30px] hover:bg-[#3A5686] font-semibold rounded-[5px] flex items-center justify-between mt-[5px] pl-[8px] ${
              status === "approved"
                ? "awaiting_verification_bene_txn_popover_btn"
                : `${status}_bene_txn_popover_btn`
            }`}
            key={status}
            style={{
              transition: "opacity 0.3s ease-in",
              opacity: isPopoverOpen ? "1" : "0",
              visibility: isPopoverOpen ? "visible" : "hidden",
              fontSize: "14px",
              color: getStatusColor(status),
              backgroundColor:
                selectedStatus === status && selectedStatus !== "all"
                  ? "#3A5686"
                  : "",
            }}
            disabled={
              (status === "require approval" && selectedStatus === status) ||
              (status === "awaiting approval" && selectedStatus === status)
            }
            onClick={() => handleStatus(status)}
          >
            {status === selectedStatus ? (
              <>
                {formatStatusText(status)}

                <img
                  src={allChecker}
                  alt="Checkmark"
                  className="all-txn-status-icon mr-1"
                />
              </>
            ) : (
              formatStatusText(status)
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BeneTxnStatusPopover;
