import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import {
  AddUserTag,
  getUsers,
  RemoveUserTag,
  setUserStoredPage,
  setUserStoredPagesize,
} from "../../../redux/features/Settings/Management/Users";
import { checkUsertoken } from "../../../redux/features/login/login";
import dataLoading from "../../../assets/LoaderIcons/Loader.gif";
import noaccount from "../../../assets/No transaction found.svg";
import ArrowDown from "../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowUp from "../../../assets/ArrowsIcons/arrow_up_white.svg";
import selectWithWhiteFilledCircle from "../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import selectWithGreenFilledCircle from "../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import FilterBlue from "../../../assets/FilterSearchIcons/filter_blue.svg";
import Filter from "../../../assets/FilterSearchIcons/filter.svg";
import tagIcon from "../../../assets/GeneralIcons/assign_tag without circle.svg";
import assigntagIcon from "../../../assets/GeneralIcons/assign_tag.svg";
import SearchIcon from "../../../assets/FilterSearchIcons/search_white.svg";
import SearchBlue from "../../../assets/FilterSearchIcons/search_blue.svg";
import ArrowDown8 from "../../../assets/ArrowsIcons/arrow_down_blue.svg";
import { useNavigate } from "react-router-dom";
import { getUserDetailsStateAction } from "../../../redux/features/users/userDetails";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import PaginationComponent from "../../../components/_utils/PaginationComponent";
import RectangleSearchBar from "../../../components/_utils/RectangleSearchBar";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import UserTag from "../../../components/UserTag";

const UsersSettings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownOptions = [50, 100];
  const [isTagsPopupOpen, setIsTagsPopupOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [multiSelect, setMultiSelect] = useState(false);
  const [totalCount, setTotalCount] = useState(false);
  const [usersDetails, setUsersDetails] = useState([]);
  const [usersArray, setUsersArray] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [AllTags, setAllTags] = useState([]);
  const [tagState, setTagState] = useState([]);
  const [newSelectedTags, setNewSelectedTags] = useState([]);
  const [searchUserText, setSearchUserText] = useState("");
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const searchRef = useRef(null);
  const [newTags, setNewTags] = useState([]);
  const filterButtonRef = useRef(null);
  const [allData, setAllData] = useState([]);
  const searchButtonRef = useRef(null);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const services = useSelector((state) => state.refreshToken.services);
  const { userStoredPage, userStoredPagesize } = useSelector(
    (state) => state.users
  );
  const [selectedAccount, setSelectedAccount] = useState(
    "All selected accounts"
  );
  const [selectedAssignedAccountID, setSelectedAssignedAccountID] =
    useState("");

  const selectedTagsCards = usersDetails?.filter((account) =>
    usersArray.includes(account.id)
  );

  let availableTags = mainData?.available_tags || tagState || [];

  useEffect(() => {
    if (Number(userStoredPage) > 1) {
      setCurrentPage(Number(userStoredPage));
    }
    if (Number(userStoredPagesize) > 50) {
      setSelectedValue(userStoredPagesize);
    }
    handleWindowSizeChange();
    sessionStorage.removeItem("userDetailsInfostorage");
  }, []);

  const handleUserDetails = (userId) => {
    let userDetailsInfo = false;
    userDetailsInfo = usersDetails.filter((fil) => {
      if (fil.id == userId) {
        return fil;
      }
    });
    if (userDetailsInfo) {
      dispatch(setUserStoredPage(currentPage));
      dispatch(setUserStoredPagesize(selectedValue));
      navigate(`/settings/Users/User_Details/${userId}`);
      dispatch(checkUsertoken());
      dispatch(getUserDetailsStateAction(userDetailsInfo));
      sessionStorage.setItem(
        "userDetailsInfostorage",
        JSON.stringify(userDetailsInfo)
      );
    }
  };

  useEffect(() => {
    if (selectedAccount !== "All selected accounts") {
      const selectedAccountTags =
        allData?.find((account) => account.id === selectedAssignedAccountID)
          ?.account_tags || [];

      setNewSelectedTags(
        selectedAccountTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );

      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else if (
      selectedTagsCards?.length === 1 &&
      selectedAccount != "All selected accounts"
    ) {
      const accountTags =
        allData?.find((account) => usersArray.includes(account.id))
          ?.account_tags || [];
      setNewSelectedTags(
        accountTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else if (
      selectedTagsCards?.length > 1 ||
      selectedAccount === "All selected accounts"
    ) {
      let a = allData?.filter((account) => usersArray.includes(account.id));
      const commonTags = a.reduce((common, account) => {
        return common.filter((tag) => account.account_tags?.includes(tag));
      }, a[0]?.account_tags || []);

      const uncommonTags = a.reduce((uncommon, account) => {
        return uncommon.concat(
          account.account_tags?.filter((tag) => !commonTags.includes(tag)) || []
        );
      }, []);

      setNewSelectedTags(
        commonTags.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );

      setAllTags(
        [...new Set([...availableTags, ...uncommonTags])].map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    } else {
      setNewSelectedTags([]);
      setAllTags(
        availableTags?.map((tag) => ({
          value: tag,
          selected: true,
          type: "ownerTags",
        }))
      );
    }
  }, [
    usersArray,
    mainData,
    selectedAssignedAccountID,
    selectedAccount,
    allData,
  ]);

  const handleAddTags = async (item) => {
    try {
      dispatch(checkUsertoken());
      setLoader(true);
      const selectedTags = newTags
        .filter((tag) => tag.selected === false)
        .map((tag) => tag.value);

      try {
      } catch (error) {}

      let response = await dispatch(
        AddUserTag({
          user_id:
            selectedAssignedAccountID !== ""
              ? [selectedAssignedAccountID]
              : usersArray,
          tags: item?.type === "newAss" ? item?.data1 : [item],
        })
      );

      if (response?.payload?.status === true) {
        setNewTags((prev) => [...prev, response?.meta?.arg?.tags]);
        fetchData(true);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleRemoveTags = async (item) => {
    try {
      dispatch(checkUsertoken());
      setLoader(true);
      const response = await dispatch(
        RemoveUserTag({
          user_id:
            selectedAssignedAccountID !== ""
              ? [selectedAssignedAccountID]
              : usersArray,
          tags: item,
        })
      );

      if (response?.payload?.status === true) {
        setNewTags(response?.payload?.tags?.value || []);
        fetchData(true);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error removing tag:", error);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    setSearchUserText(e.target.value);
  };

  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(192);
    }
  }

  useEffect(() => {
    const handleDropDown = (e) => {
      if (searchRef.current && searchRef.current.contains(e.target)) {
      } else if (
        searchButtonRef.current &&
        searchButtonRef.current.contains(e.target)
      ) {
      } else {
        setShowSearchBar(false);
      }
    };
    document.addEventListener("click", handleDropDown);
  }, [searchRef]);

  const fetchData = async (val) => {
    let queryParams = {
      page_size: pageSize,
      search_string: val ? "" : searchUserText,
    };
    setLoader(true);
    try {
      dispatch(checkUsertoken());
      const response = await dispatch(getUsers(queryParams));
      const newData = response?.payload?.employees || [];
      if (searchUserText.length === 0) {
        setTagState(response?.payload?.available_tags);
      }
      setMainData(response?.payload || []);

      setTotalCount(response?.payload?.total_count);

      setLoader(false);

      setAllData((prev) => {
        return [
          ...new Map(
            [...prev, ...newData].map((item) => [item["id"], item])
          ).values(),
        ];
      });
      setUsersDetails(newData);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (services && services.includes("Admin")) {
      if (!services.includes("MimicEntity")) {
        navigate("/feature_not_assigned");
      } else {
        navigate("/settings/Users");
      }
    } else {
      if (services && !services.includes("VEmp")) {
        navigate("/feature_not_assigned");
      } else {
        navigate("/settings/Users");
      }
    }

    fetchData();
  }, [searchUserText, pageSize, currentPage]);

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {
        <div
          style={{
            zIndex: filterOpen ? "0" : "9999",
            width: "100%",
            bottom: "0",
          }}
          className="fixed w-full bg-[#244277] h-[50px]"
        >
          <PaginationComponent
            handleSelect={handleSelect}
            selectedValue={selectedValue}
            data={mainData}
            pageSize={pageSize}
            dropdownOptions={dropdownOptions}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
      }
      {isTagsPopupOpen && (
        <div
          onClick={() => setIsTagsPopupOpen(false)}
          className="fixed left-0 w-full top-0 h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[99999]"
        >
          <UserTag
            onClose={() => setIsTagsPopupOpen(false)}
            newTags={newSelectedTags}
            setNewTags={setNewSelectedTags}
            AllTags={AllTags}
            setAllTags={setAllTags}
            ownerDetailData={[
              ...new Map(allData.map((item) => [item["id"], item])).values(),
            ].filter((account) => usersArray.includes(account.id))}
            type="userViewAll"
            loading={loader}
            setLoading={setLoader}
            handleAddTags={handleAddTags}
            handleRemoveTags={handleRemoveTags}
            selectedAssignedAccountID={selectedAssignedAccountID}
            setSelectedAssignedAccountID={setSelectedAssignedAccountID}
            selectedTagsCards={[
              ...new Map(allData.map((item) => [item["id"], item])).values(),
            ].filter((account) => usersArray.includes(account.id))}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            users={[
              ...new Map(allData.map((item) => [item["id"], item])).values(),
            ]
              .filter((account) => usersArray.includes(account.id))
              ?.map((ac) => ac.first_name)}
          />
        </div>
      )}
      <div
        className={`bg-custom-appBack flex flex-col h-screen overflow-y-hidden`}
        style={{
          overflow: filterOpen && "hidden",
        }}
      >
        <div
          className={`owner_div_container ${
            multiSelect ? "h-[150px]" : " h-[105px]"
          } ${
            showSearchBar && !multiSelect
              ? "owner_div_height"
              : showSearchBar && multiSelect
              ? "userViewallContainer"
              : ""
          }`}
        >
          <div className={"owner_div"}>
            {multiSelect ? (
              <div className="w-[591px] mt-[45px] flex gap-[20px]">
                <div
                  onClick={() => {
                    if (usersArray.length > 0) {
                      setUsersArray([]);
                    } else {
                      setSearchUserText("");
                      setCurrentPage(1);
                      setSelectedValue(50);
                      setMultiSelect(false);
                    }
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                  className="w-[125px] h-[60px] hover:bg-[#f9f9f9] hover:bg-opacity-20 bg-[#506994] flex justify-center items-center"
                >
                  <p
                    className={`${
                      usersArray.length > 0 ? "w-[39px]" : "w-[50px]"
                    } h-[22px] text-[16px] text-[#f9f9f9] `}
                  >
                    {usersArray.length > 0 ? "Clear" : "Cancel"}
                  </p>
                </div>
                <div className="w-[158px] py-[10px] flex flex-col">
                  <p className="text-[14px] font-semibold text-[#f9f9f9]">
                    {usersArray?.length}
                  </p>
                  <p className="text-[12px] text-[#dadada]">
                    {usersArray?.length > 1
                      ? "Users selected"
                      : "User selected"}
                  </p>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "102px",
                  height: " 105px",
                  transition: "height 0.2s ease-in-out",
                  padding: "45px 20px",
                }}
                className=" all-containers"
              >
                <div
                  style={{
                    color: "#f9f9f9",
                  }}
                  className="Nos-of-balancess"
                >
                  {totalCount || "0"}
                </div>
                <div className="views-counts">
                  <p>Total users</p>
                </div>
              </div>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "105px",
                width: multiSelect ? "197px" : "293px",
                paddingTop: "45px",
              }}
            >
              <div
                style={{
                  display: !multiSelect ? "flex" : "none",
                  opacity:
                    filterOpen ||
                    showSearchBar ||
                    (services && !services.includes("Admin"))
                      ? "0.6"
                      : "1",
                  cursor:
                    filterOpen ||
                    showSearchBar ||
                    (services && !services.includes("Admin"))
                      ? "default"
                      : "pointer",
                }}
                onClick={() => {
                  if (services && services.includes("Admin")) {
                    if (!showSearchBar && !filterOpen) {
                      setSearchUserText("");
                      setCurrentPage(1);
                      setSelectedValue(50);
                      setMultiSelect(true);
                    }
                  } else {
                    navigate("/feature_not_assigned");
                  }
                }}
                className={`assigntagdiv ${
                  services &&
                  services.includes("Admin") &&
                  "hover:bg-custom-cardHover"
                }`}
              >
                <img src={assigntagIcon} alt="" />
                <span>Assign tags to users</span>
              </div>
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                      zIndex: "2147483647 !important",
                    },
                  },
                }}
                title="Search"
              >
                <div
                  ref={searchButtonRef}
                  style={{
                    backgroundColor: searchUserText.length > 0 && "#f9f9f9",
                    opacity: filterOpen ? "0.6" : "1",
                    cursor: filterOpen ? "default" : "pointer",
                  }}
                  className="owner_search_div hover:bg-custom-cardHover"
                  onClick={() => {
                    if (!filterOpen) {
                      setShowSearchBar(!showSearchBar);
                    }
                  }}
                >
                  <img
                    className="h-[14px] w-[14px]"
                    src={searchUserText.length > 0 ? SearchBlue : SearchIcon}
                    alt="search-image"
                  />
                  <img
                    style={{
                      transform: showSearchBar && "rotate(180deg)",
                    }}
                    src={searchUserText.length > 0 ? ArrowDown8 : ArrowDown}
                    alt=""
                    className="filter-images"
                  />
                </div>
              </Tooltip>

              <div
                style={{ display: !multiSelect ? "flex" : "none" }}
                ref={filterButtonRef}
                onClick={(e) => {
                  toast.info("Feature coming soon", toast_position);
                  e.stopPropagation();
                  // if (!showSearchBar) {
                  //   setFilterOpen(!filterOpen);
                  // }
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                        zIndex: "2147483647 !important",
                      },
                    },
                  }}
                  title="Filters"
                >
                  <div
                    style={{
                      pointerEvents: "none",
                      // backgroundColor:

                      //   ifscstate.length > 0
                      //     ? "#F9F9F9"
                      //     : "",
                      // opacity: showSearchBar ? "0.6" : "1",
                      // cursor: showSearchBar ? "default" : "pointer",
                      cursor: "default",
                      opacity: "0.6",
                    }}
                    className="filter-containers"
                  >
                    <Box className="filters-box">
                      <img
                        src={
                          // typepop === "popup" ||
                          // connectedbankingstate.length > 0 ||
                          // accountbalancestate.length > 0 ||
                          // ownerTagState.length > 0 ||
                          // typeaccountstate.length > 0 ||
                          // bankstate.length > 0 ||
                          // panstate.length > 0 ||
                          // accountnumberstate.length > 0 ||
                          // ifscstate.length > 0
                          //   ? FilterBlue
                          Filter
                        }
                        alt=""
                        className="filter-images"
                      />
                    </Box>
                    <Box className="filter-box">
                      <img
                        src={
                          // filterOpen
                          //   ? typepop === "popup" ||
                          //     connectedbankingstate.length > 0 ||
                          //     accountbalancestate.length > 0 ||
                          //     ownerTagState.length > 0 ||
                          //     typeaccountstate.length > 0 ||
                          //     bankstate.length > 0 ||
                          //     panstate.length > 0 ||
                          //     accountnumberstate.length > 0 ||
                          //     ifscstate.length > 0
                          //     ? ArrowUp4
                          //     : ArrowUp
                          //   : typepop === "popup" ||
                          //     connectedbankingstate.length > 0 ||
                          //     accountbalancestate.length > 0 ||
                          //     ownerTagState.length > 0 ||
                          //     typeaccountstate.length > 0 ||
                          //     bankstate.length > 0 ||
                          //     panstate.length > 0 ||
                          //     accountnumberstate.length > 0 ||
                          //     ifscstate.length > 0
                          //   ? ArrowDown8
                          ArrowDown
                        }
                        alt=""
                        className="filter-images"
                      />
                    </Box>
                  </div>
                </Tooltip>
              </div>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  if (usersArray?.length > 0) {
                    setUsersArray(usersArray);
                    setIsTagsPopupOpen(true);
                  }
                }}
                style={{
                  display: multiSelect ? "flex" : "none",
                  backgroundColor:
                    usersArray?.length > 0 ? "#F9F9F9" : "#707070",
                  cursor: usersArray?.length > 0 ? "pointer" : "default",
                  opacity: usersArray?.length > 0 ? "1" : "0.8",
                }}
                className={`w-[125px] h-[60px] flex justify-center items-center ${
                  usersArray?.length > 0 && " hover:bg-opacity-80"
                }`}
              >
                <p
                  className={`w-[96px] h-[22px] text-[16px] ${
                    usersArray?.length > 0 ? "text-[#1D3A6D]" : "text-[#f9f9f9]"
                  }`}
                >
                  Assign Tag/s
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: multiSelect ? "flex" : "none",
              borderTop: "1px solid #031F4F",
              height: "36px",
            }}
            className="w-[1024px] mx-auto py-[10px] relative"
          >
            <p
              style={{ maxWidth: "none", width: "auto", textTransform: "none" }}
              className="user-id multiselectanimation"
            >
              Select users to assign tags. Tagged users will gain access to view
              owner accounts that share the same tags.
            </p>
          </div>
          <div className="w-full mx-auto">
            {showSearchBar && (
              <RectangleSearchBar
                multiSelect={multiSelect}
                handleSearch={setSearchUserText}
                showSearchBar={showSearchBar}
                searchUserText={searchUserText}
                setSearchUserText={setSearchUserText}
                onPaste={handlePaste}
                toggleShowSearchBar={() => {
                  setFilterOpen(false);
                  setShowSearchBar(!showSearchBar);
                }}
                bgColor={"bg-[#1D3A6D]"}
                bgHover={"hover:bg-custom-cardHover"}
                setCurrentPage={setCurrentPage}
                searchRef={searchRef}
                placeholder="Search by first name/ last name/ user ID/ full name"
              />
            )}
          </div>
        </div>
        {loader ? (
          <>
            <div className="loader-container m-auto justify-center items-center flex loader-height">
              <img src={dataLoading} alt="" />
            </div>
          </>
        ) : usersDetails.length != 0 ? (
          <>
            <div
              className={
                filterOpen
                  ? "overflow-y-hidden h-screen ownerviewallscroll opacity-80  bg-black mt-[5px]"
                  : "overflow-y-auto h-screen mb-[50px] ownerviewallscroll mt-[5px]"
              }
            >
              <div
                style={{
                  opacity: "1",
                }}
                className="UserCard"
              >
                {usersDetails.map((userDtls) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          multiSelect &&
                          usersArray?.includes(userDtls.id) &&
                          "#4A6D4B",
                      }}
                      key={userDtls?.id}
                      className="user-details-card"
                      onClick={(e) => {
                        if (multiSelect) {
                          e.stopPropagation();

                          if (usersArray?.includes(userDtls.id)) {
                            setUsersArray(() => {
                              return usersArray?.filter((acc) => {
                                return acc !== userDtls.id;
                              });
                            });
                          } else {
                            setUsersArray([...usersArray, userDtls.id]);
                          }
                        } else {
                          handleUserDetails(userDtls.id);
                        }
                      }}
                    >
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={userDtls.first_name + " " + userDtls.last_name}
                      >
                        <div className="w-[215px] flex justify-between">
                          <div className="user-name truncate">
                            {userDtls.first_name + " " + userDtls.last_name}
                          </div>
                          <div
                            style={{ display: multiSelect ? "flex" : "none" }}
                            className="h-[16px] w-[16px] my-[3px]"
                          >
                            <img
                              src={
                                usersArray?.includes(userDtls.id)
                                  ? selectWithGreenFilledCircle
                                  : selectWithWhiteFilledCircle
                              }
                              alt="white-filled-check"
                            />
                          </div>
                        </div>
                      </Tooltip>
                      <div className="user-id">{userDtls.user_id}</div>
                      <div className="user-label flex justify-between">
                        User ID
                      </div>
                      <div className="userborderdiv"></div>
                      <div className="user-label">
                        <img src={tagIcon} alt="" />
                        <span>Assigned tags</span>
                      </div>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            placement: "bottom",
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              zIndex: "2147483647 !important",
                            },
                          },
                        }}
                        title={String(userDtls?.account_tags)}
                      >
                        <div className="user-id">
                          {userDtls?.account_tags.length > 0 ? (
                            userDtls?.account_tags?.map((a, i) => {
                              if (i == userDtls?.account_tags.length - 1) {
                                return a;
                              } else {
                              }
                              return a + ", ";
                            })
                          ) : (
                            <span>No tags assigned</span>
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col items-center justify-center m-auto">
              <img
                src={noaccount}
                alt="No accounts found"
                className="bene-acc-no-acc-image"
              />
              <p className="user-not-found">No users found.</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default UsersSettings;
